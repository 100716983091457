/**
 * Converts a Unix timestamp to a formatted date string in the user's local timezone.
 * @param timestamp - The Unix timestamp to convert (in miliseconds).
 * @param options - Optional formatting options.
 * @returns A formatted date string.
 */
const formatTimestampToDate = (timestamp, options) => {
  const date = new Date(timestamp)

  const day = date.getDate()
  const month = date.toLocaleString('default', { month: 'short' })
  const year = date.getFullYear()

  let formattedDate = ''

  if (options?.showDay !== false) {
    formattedDate += `${day}`
  }
  if (options?.showMonth !== false) {
    formattedDate += ` ${month}`
  }
  if (options?.showYear) {
    formattedDate += ` ${year}`
  }

  return formattedDate.trim()
}

export default formatTimestampToDate
