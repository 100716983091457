import i18n from 'i18next'

/**
 * Converts a duration in seconds to a human-readable format.
 * @param durationInSeconds - The duration in seconds.
 * @returns A human-readable string representing the duration.
 */
const formatDuration = (durationInSeconds) => {
  const secondsPerMinute = 60
  const secondsPerHour = secondsPerMinute * 60
  const secondsPerDay = secondsPerHour * 24
  const secondsPerWeek = secondsPerDay * 7
  const secondsPerMonth = secondsPerDay * 30 // Approximate
  const secondsPerYear = secondsPerDay * 365 // Approximate

  // Determine if the duration is a whole number of years
  if (durationInSeconds % secondsPerYear === 0) {
    const years = durationInSeconds / secondsPerYear
    return `${years} ${years !== 1 ? i18n.t('common.Years') : i18n.t('common.Year')}`
  }

  // Determine if the duration is a whole number of months
  if (durationInSeconds % secondsPerMonth === 0) {
    const months = durationInSeconds / secondsPerMonth
    return `${months} ${months !== 1 ? i18n.t('common.Months') : i18n.t('common.Month')}`
  }

  // Calculate years and remaining months
  const years = Math.floor(durationInSeconds / secondsPerYear)
  const remainingSeconds = durationInSeconds % secondsPerYear
  const months = Math.floor(remainingSeconds / secondsPerMonth)

  // Construct the output based on years and months
  if (years > 0 && months > 0) {
    return `${years} ${years !== 1 ? i18n.t('common.Years') : i18n.t('common.Year')} ${months} ${months !== 1 ? i18n.t('common.Months') : i18n.t('common.Month')}`
  }
  if (years > 0) {
    return `${years} ${years !== 1 ? i18n.t('common.Years') : i18n.t('common.Year')}`
  }
  if (months > 0) {
    return `${months} ${months !== 1 ? i18n.t('common.Months') : i18n.t('common.Month')}`
  }

  // Determine if the duration is a whole number of weeks
  if (durationInSeconds % secondsPerWeek === 0) {
    const weeks = durationInSeconds / secondsPerWeek
    return `${weeks} ${weeks !== 1 ? i18n.t('common.Weeks') : i18n.t('common.Week')}`
  }

  // Determine if the duration is a whole number of days
  if (durationInSeconds % secondsPerDay === 0) {
    const days = durationInSeconds / secondsPerDay
    return `${days} ${days !== 1 ? i18n.t('common.Days') : i18n.t('common.Day')}`
  }

  // General cases for other durations
  if (durationInSeconds < secondsPerMinute) {
    return `${durationInSeconds} ${
      durationInSeconds !== 1
        ? i18n.t('common.Seconds')
        : i18n.t('common.Second')
    }`
  }
  if (durationInSeconds < secondsPerHour) {
    const minutes = Math.floor(durationInSeconds / secondsPerMinute)
    return `${minutes} ${minutes !== 1 ? i18n.t('common.Minutes') : i18n.t('common.Minute')}`
  }
  if (durationInSeconds < secondsPerDay) {
    const hours = Math.floor(durationInSeconds / secondsPerHour)
    return `${hours} ${hours !== 1 ? i18n.t('common.Hours') : i18n.t('common.Hour')}`
  }

  return '' // Handle edge case if none of the conditions match
}

export default formatDuration
