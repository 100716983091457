import { COIN_SYMBOLS } from '@/constants/coins'
import { formatBalance } from '@/utils/formatBalance'
import { Button, HStack, VStack } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { PoolCardAction } from './poolCard'

const JoinPoolAction = ({ onJoinPool, onMoveDeposit, movableState }) => {
  const { t } = useTranslation()
  return (
    <>
      <PoolCardAction
        action={movableState ? onMoveDeposit : onJoinPool}
        w='full'
        variant='action'
      >
        {t('pools.Join Pool')}{' '}
        {movableState &&
          `with ${formatBalance(movableState.amount, 4, false)} ${COIN_SYMBOLS[movableState.token]}`}
      </PoolCardAction>
    </>
  )
}

const BoostAndCashoutAction = ({
  onBoost,
  onCashout,
  movableState,
  onMoveDeposit,
}) => {
  const { t } = useTranslation()
  return (
    <HStack
      flexDir={{ base: 'column', md: 'row' }}
      alignItems='stretch'
      gap={{ base: '8px', md: '16px' }}
    >
      <PoolCardAction
        action={movableState ? onMoveDeposit : onBoost}
        w='full'
        flex={1}
        variant='action'
      >
        {t('pools.Boost')}{' '}
        {movableState &&
          `with ${formatBalance(movableState.amount, 4, false)} ${COIN_SYMBOLS[movableState.token]}`}
      </PoolCardAction>

      {!movableState && (
        <PoolCardAction action={onCashout} flex={1} variant='outlineWhite'>
          {t('pools.Cashout')}
        </PoolCardAction>
      )}
    </HStack>
  )
}

const ClaimPrizeAction = ({ onClaim, isClaimed, onMoveDeposit }) => {
  const { t } = useTranslation()
  return isClaimed ? (
    <VStack>
      <Button isDisabled variant='action'>
        {t('pools.You have claimed your reward!')}
      </Button>
    </VStack>
  ) : (
    <VStack alignItems={'stretch'}>
      <PoolCardAction
        action={onClaim}
        withLoader={true}
        loadingText={`${t('pools.Claiming')}...`}
        variant='action'
      >
        {t('pools.Claim Prize')}
      </PoolCardAction>

      <PoolCardAction action={onMoveDeposit} variant='outlineWhite'>
        {t('pools.Move Deposit')}
      </PoolCardAction>
    </VStack>
  )
}

const CashoutAction = ({ onCashout, isCashouted, onMoveDeposit }) => {
  const { t } = useTranslation()
  return isCashouted ? (
    <Button isDisabled>{t('pools.Already cashed out')}</Button>
  ) : (
    <VStack alignItems={'stretch'}>
      <PoolCardAction
        action={onCashout}
        withLoader={true}
        flex={1}
        loadingText={`${t('pools.Cashing out')}...`}
        variant='outlineWhite'
      >
        {t('pools.Cashout')}
      </PoolCardAction>

      {onMoveDeposit && (
        <PoolCardAction action={onMoveDeposit} variant='outlineWhite'>
          {t('pools.Move Deposit')}
        </PoolCardAction>
      )}
    </VStack>
  )
}

const FinalizeAction = ({ onFinalize, isJoined }) => {
  const { t } = useTranslation()

  if (isJoined) {
    return (
      <PoolCardAction
        action={onFinalize}
        withLoader={true}
        flex={1}
        loadingText={`${t('pools.Finalizing')}...`}
        variant='action'
      >
        {t('pools.Finalize Pool')}
      </PoolCardAction>
    )
  }

  return (
    <Button whiteSpace={'unset'} isDisabled variant='action'>
      {t('pools.Winners will be announced soon!')}
    </Button>
  )
}

export {
  JoinPoolAction,
  BoostAndCashoutAction,
  ClaimPrizeAction,
  CashoutAction,
  FinalizeAction,
}
