import { COIN_ICONS } from '@/constants/coins'
import { HStack, Image, Text, Tooltip } from '@chakra-ui/react'
import { PoolCardInfoBadge } from '..'

export default function UserInfo({
  isJoined,
  isPoolLastDay,
  tooltipChance,
  chance,
  isFinished,
  isStarted,
  isNotStarted,
  fullDeposit,
  deposit,
  token,
  t,
  isGameClosed,
}) {
  if (isJoined) {
    return (
      <HStack
        mt={{ base: '12px', md: '24px' }}
        justifyContent={'space-between'}
        alignItems={'flex-start'}
        flexDirection={{ base: 'column', md: 'row' }}
      >
        <HStack gap={'4px'} flexWrap={'wrap'} flex={1}>
          <Text fontWeight={600} fontSize={{ base: '20px', md: '24px' }}>
            {t('pools.My Deposit')}
          </Text>

          <HStack wordBreak={'break-word'} gap={1}>
            <Tooltip label={`${fullDeposit} FTN`}>
              <Text
                as='span'
                fontSize={{ base: '20px', md: '24px' }}
                fontWeight={500}
                color={'alcohol.300'}
              >
                {deposit}
              </Text>
            </Tooltip>

            <Image src={COIN_ICONS[token]} alt='Token' w={'19px'} h={'20px'} />
          </HStack>
        </HStack>

        {!isFinished && (
          <Tooltip label={`${tooltipChance}%`}>
            <PoolCardInfoBadge
              maxW={300}
              px={'15px'}
              title={`${t('pools.My Chance')}: `}
              value={`${chance}%`}
              bgColor={'success.500'}
            />
          </Tooltip>
        )}
      </HStack>
    )
  }

  if (isNotStarted) {
    return (
      <Text
        mt={{ base: '12px', md: '24px' }}
        textAlign={'center'}
        lineHeight={{ base: '28px', sm: '38px', md: '48px' }}
        fontWeight={600}
        fontSize={{ base: '20px', md: '24px' }}
      >
        {t('pools.The pool will start soon!')}
      </Text>
    )
  }

  if (!isJoined && isStarted && !isPoolLastDay && !isGameClosed) {
    return (
      <Text
        mt={{ base: '12px', md: '24px' }}
        textAlign={'center'}
        lineHeight={{ base: '28px', sm: '38px', md: '48px' }}
        fontWeight={600}
        fontSize={{ base: '20px', md: '24px' }}
      >
        {t("pools.It's not too late to join the pool!")}
      </Text>
    )
  }
}
