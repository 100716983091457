import Pools from '@/components/pool/Pools'
import { AbsoluteSpinner, Page, Title } from '@/components/shared'
import { getPool } from '@/core/pool/common'
import useFinishedPools from '@/hooks/useFinishedPools'
import { Flex } from '@chakra-ui/react'
import { useQueryClient } from '@tanstack/react-query'
import { MainContainer } from '@vaultwin/ui'
import { useState } from 'react'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useAccount } from 'wagmi'

const FinishedPools = () => {
  const queryClient = useQueryClient()
  const { address: userAddress } = useAccount()
  const { t } = useTranslation()
  const [filters, setFilters] = useState({})
  const {
    data: finishedPools,
    refetch,
    isLoading,
  } = useFinishedPools(userAddress, filters)

  const refetchPool = async ({ poolAddress }) => {
    const updatedPool = await getPool({
      poolAddress,
      userAddress,
    })

    queryClient.setQueryData(['finished-pools'], (oldPools = []) =>
      oldPools.map((pool) =>
        pool.address === poolAddress ? updatedPool : pool,
      ),
    )

    queryClient.setQueryData(['pool', poolAddress, userAddress], updatedPool)
  }

  useEffect(() => {
    if (finishedPools) {
      for (const pool of finishedPools) {
        if (!pool) continue

        queryClient.setQueryData(['pool', pool.address, userAddress], pool)
      }
    }
  }, [finishedPools, queryClient.setQueryData, userAddress])

  // biome-ignore lint: <explanation>
  useEffect(() => {
    refetch()
  }, [userAddress])

  return (
    <Page>
      <MainContainer>
        <Flex
          alignItems={'center'}
          justifyContent={'center'}
          gap={'16px'}
          direction={'column'}
          mb={'40px'}
        >
          <Flex
            mt={4}
            direction={{ base: 'column', md: 'row' }}
            alignItems={'center'}
            justifyContent={'center'}
          >
            <Flex alignItems={'center'}>
              <Title
                lineHeight={'normal'}
                mr={'4px'}
                textAlign={'center'}
                fontWeight={600}
              >
                {t('pools.Finished Pools')}
              </Title>
            </Flex>
          </Flex>
        </Flex>

        {isLoading && <AbsoluteSpinner />}

        {!isLoading && finishedPools && (
          <Pools
            pools={finishedPools}
            userAddress={userAddress}
            refetchPool={refetchPool}
            setFilters={setFilters}
          />
        )}
      </MainContainer>
    </Page>
  )
}

export default FinishedPools
