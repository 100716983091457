import ticketCardBgUrl from '@/assets/ticket-card-bg.png'
import { VStack } from '@chakra-ui/react'

const PoolCardContainer = ({ children, ...props }) => {
  return (
    <VStack
      maxW={{ base: 'full', sm: 500, md: 642 }}
      minW={{ base: 'unset', sm: 400, lg: 450 }}
      alignItems={'stretch'}
      justifyContent={'space-between'}
      gap={0}
      p={{ base: '16px', sm: '18px', md: '24px' }}
      borderRadius={'20px'}
      bgBlendMode='overlay'
      bgGradient='linear-gradient(0deg, #170A33 0%, #3A0E99 100%)'
      position={'relative'}
      zIndex={1}
      _before={{
        content: `''`,
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        bgImage: `url(${ticketCardBgUrl})`,
        bgRepeat: 'no-repeat',
        bgSize: 'cover',
        bgPosition: 'center',
        opacity: 0.2,
        zIndex: -1,
      }}
      {...props}
    >
      {children}
    </VStack>
  )
}

export default PoolCardContainer
