import { getPool } from '@/core/pool/common'
import { useQuery } from '@tanstack/react-query'

const usePool = (poolAddress, userAddress) => {
  return useQuery({
    queryKey: ['pool', poolAddress, userAddress],
    queryFn: () =>
      getPool({
        poolAddress,
        userAddress,
      }),
  })
}

export default usePool
