import { config } from '@/configs/wagmi'
import { COINS_ADDRESSES } from '@/constants/coins'
import { CONTRACT_ABI_BY_VERSIONS } from '@/constants/v2/poolVersions'
import {
  estimateGas,
  waitForTransactionReceipt,
  writeContract,
} from '@wagmi/core'
import i18n from 'i18next'
import { TransactionExecutionError, erc20Abi } from 'viem'

export const approveERC20Action = async ({
  poolAddress,
  value,
  toast,
  tokenAddress,
}) => {
  try {
    const hash = await writeContract(config, {
      abi: erc20Abi,
      address: tokenAddress,
      functionName: 'approve',
      args: [poolAddress, value],
      // gas: 500_000n,
    })

    if (!hash) {
      throw new Error(i18n.t('errors.Transaction failed'))
    }

    const result = await waitForTransactionReceipt(config, { hash })

    if (!result || result.status !== 'success') {
      throw new Error(i18n.t('errors.Transaction failed'))
    }

    return result
  } catch (error) {
    console.error('Error in ApproveERC20Action: ', error.message)

    if (error instanceof TransactionExecutionError) {
      return
    }

    toast({
      title: i18n.t('errors.Transaction failed'),
      status: 'error',
      duration: 5000,
      isClosable: true,
    })
  }
}

export const stakeAction = async ({
  poolAddress,
  value,
  toast,
  yieldSource,
  version,
  token,
}) => {
  try {
    const isFTN = token === COINS_ADDRESSES.FTN
    let hash

    if (isFTN) {
      hash = await writeContract(config, {
        abi: CONTRACT_ABI_BY_VERSIONS[yieldSource][token][version],
        address: poolAddress,
        functionName: 'stake',
        value,
        gas: 500_000n,
      })
    } else {
      hash = await writeContract(config, {
        abi: CONTRACT_ABI_BY_VERSIONS[yieldSource][token][version],
        address: poolAddress,
        functionName: 'stake',
        args: [value],
        // gas: 500_000n,
      })
    }

    if (!hash) {
      throw new Error(i18n.t('errors.Transaction failed'))
    }

    const result = await waitForTransactionReceipt(config, { hash })

    if (!result || result.status !== 'success') {
      throw new Error(i18n.t('errors.Transaction failed'))
    }

    return result
  } catch (error) {
    console.error('Error in stakeAction: ', error.message)

    if (error instanceof TransactionExecutionError) {
      return
    }

    toast({
      title: i18n.t('errors.Transaction failed'),
      status: 'error',
      duration: 5000,
      isClosable: true,
    })
  }
}

export const unstakeAction = async ({
  poolAddress,
  toast,
  version,
  yieldSource,
  token,
}) => {
  try {
    const estimatedGas = await estimateGas(config, {
      to: poolAddress,
      data: '0x2def6620',
    })
    const gas = estimatedGas
      ? estimatedGas + (estimatedGas * 10n) / 100n
      : 900_000n

    const hash = await writeContract(config, {
      abi: CONTRACT_ABI_BY_VERSIONS[yieldSource][token][version],
      address: poolAddress,
      functionName: 'unstake',
      args: [],
      gas,
    })

    if (!hash) {
      throw new Error(i18n.t('errors.Transaction failed'))
    }

    const result = await waitForTransactionReceipt(config, { hash })

    if (!result || result.status !== 'success') {
      throw new Error(i18n.t('errors.Transaction failed'))
    }

    return result
  } catch (error) {
    console.error('Error in unstakeAction: ', error.message)

    if (error instanceof TransactionExecutionError) {
      return
    }

    toast({
      title: i18n.t('errors.Transaction failed'),
      description: error.message,
      status: 'error',
      duration: 5000,
      isClosable: true,
    })
  }
}

export const finalizePoolAction = async ({
  poolAddress,
  yieldSource,
  version,
  toast,
  token,
}) => {
  try {
    const hash = await writeContract(config, {
      functionName: 'finalizeRound',
      abi: CONTRACT_ABI_BY_VERSIONS[yieldSource][token][version],
      address: poolAddress,
      args: [],
    })

    if (!hash) {
      throw new Error(i18n.t('errors.Transaction failed'))
    }

    const result = await waitForTransactionReceipt(config, { hash })

    if (!result || result.status !== 'success') {
      throw new Error(i18n.t('errors.Transaction failed'))
    }

    return result
  } catch (error) {
    console.error('Error in finalizeActions: ', error.message)

    if (error instanceof TransactionExecutionError) {
      return
    }

    toast({
      title: i18n.t('errors.Transaction failed'),
      status: 'error',
      duration: 5000,
      isClosable: true,
    })
  }
}

export const claimAction = async ({
  poolAddress,
  yieldSource,
  version,
  toast,
  token,
}) => {
  try {
    const hash = await writeContract(config, {
      abi: CONTRACT_ABI_BY_VERSIONS[yieldSource][token][version],
      address: poolAddress,
      functionName: 'claim',
      args: [],
    })

    if (!hash) {
      throw new Error(i18n.t('errors.Transaction failed'))
    }

    const result = await waitForTransactionReceipt(config, { hash })

    if (!result || result.status !== 'success') {
      throw new Error(i18n.t('errors.Transaction failed'))
    }

    return result
  } catch (error) {
    console.error('Error in claimAction: ', error.message)

    if (error instanceof TransactionExecutionError) {
      return
    }

    toast({
      title: i18n.t('errors.Transaction failed'),
      status: 'error',
      duration: 5000,
      isClosable: true,
    })
  }
}

export const moveDepositAction = async ({
  poolAddress,
  yieldSource,
  version,
  toast,
  token,
  poolAddressToMove,
}) => {
  try {
    const hash = await writeContract(config, {
      functionName: 'transferFundsToNewPool',
      abi: CONTRACT_ABI_BY_VERSIONS[yieldSource][token][version],
      address: poolAddress,
      args: [poolAddressToMove],
    })

    if (!hash) {
      throw new Error(i18n.t('errors.Transaction failed'))
    }

    const result = await waitForTransactionReceipt(config, { hash })

    if (!result || result.status !== 'success') {
      throw new Error(i18n.t('errors.Transaction failed'))
    }

    return result
  } catch (error) {
    console.error('Error in Move deposit actions: ', error.message)

    if (error instanceof TransactionExecutionError) {
      return
    }

    toast({
      title: i18n.t('errors.Transaction failed'),
      status: 'error',
      duration: 5000,
      isClosable: true,
    })
  }
}
