import POOL_STATUSES from '@/constants/poolStatus'
import { getLivePools } from '@/core/pool/common'
import { isPoolLastDayState } from '@/utils/poolUtils'
import { useQuery } from '@tanstack/react-query'

const useLivePools = (userAddress, filters, onlyUserPools = false) => {
  return useQuery({
    queryKey: ['live-pools'],
    queryFn: () => getLivePools({ userAddress }),
    select: (data) => {
      const {
        token,
        isPoolLastDayFilter,
        appVersion,
        status = [POOL_STATUSES.started, POOL_STATUSES.gameClosed],
      } = filters || {}

      return data.filter((pool) => {
        const isPoolLastDay = isPoolLastDayState({
          status: pool?.poolInfo?.status,
          end: pool?.poolInfo?.end,
        })

        const matchesStatus = status.includes(pool?.poolInfo?.status)
        const matchesUserPools = onlyUserPools ? pool?.userInfo?.id > 0n : true
        const matchesToken = token ? pool?.token === token : true
        const matchesIsPoolLastDay =
          typeof isPoolLastDayFilter === 'boolean'
            ? isPoolLastDay === isPoolLastDayFilter
            : true
        const matchesAppVersion = appVersion
          ? pool?.appVersion === appVersion
          : true

        return (
          matchesUserPools &&
          matchesToken &&
          matchesIsPoolLastDay &&
          matchesAppVersion &&
          matchesStatus
        )
      })
    },
  })
}

export default useLivePools
