import { COIN_ICONS } from '@/constants/coins'
import { Image } from '@chakra-ui/react'

const Coin = ({ token }) => (
  <Image
    src={COIN_ICONS[token]}
    alt='FTN'
    w={{ base: '32px', md: '42px' }}
    h={{ base: '32px', md: '42px' }}
  />
)

export default Coin
