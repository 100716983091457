import { getFinishedPools } from '@/core/pool/common'
import { useQuery } from '@tanstack/react-query'

const useFinishedPools = (userAddress, filters = {}, onlyUserPools = false) => {
  return useQuery({
    queryKey: ['finished-pools'],
    queryFn: () => getFinishedPools({ userAddress }),
    select: (data) => {
      const { token } = filters

      const finishedPools = data.filter((pool) => {
        const matchesToken = token ? pool?.token === token : true
        const matchesOnlyUserPools = onlyUserPools
          ? pool?.userInfo?.id > 0n
          : true

        return pool && matchesToken && matchesOnlyUserPools
      })

      return finishedPools.sort(
        (a, b) => Number(b.poolInfo.end) - Number(a.poolInfo.end),
      )
    },
  })
}

export default useFinishedPools
