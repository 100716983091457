import Pools from '@/components/pool/Pools'
import { AbsoluteSpinner, Page, Title } from '@/components/shared'
import { APP_VERSIONS } from '@/constants/appVersions'
import { COIN_SYMBOLS } from '@/constants/coins'
import POOL_STATUSES from '@/constants/poolStatus'
import { getPool } from '@/core/pool/common'
import useLivePools from '@/hooks/useLivePools'
import { formatBalance } from '@/utils/formatBalance'
import { ArrowBackIcon } from '@chakra-ui/icons'
import { Button, Flex, Text } from '@chakra-ui/react'
import { useQueryClient } from '@tanstack/react-query'
import { MainContainer } from '@vaultwin/ui'
import { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { useAccount } from 'wagmi'

const LivePools = () => {
  const queryClient = useQueryClient()
  const { address: userAddress } = useAccount()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const {
    amount: movableAmount,
    movableState,
    token: movableToken,
    poolAddress: movablePoolAddress,
  } = location.state || {}
  const [filters, setFilters] = useState({})
  const {
    data: livePools,
    isLoading,
    refetch,
  } = useLivePools(userAddress, filters)

  const refetchPool = async ({ poolAddress }) => {
    const updatedPool = await getPool({
      poolAddress,
      userAddress,
    })

    queryClient.setQueryData(['live-pools'], (oldPools) =>
      oldPools.map((pool) =>
        pool && pool.address === poolAddress ? updatedPool : pool,
      ),
    )

    queryClient.setQueryData(['pool', poolAddress, userAddress], updatedPool)
  }

  useEffect(() => {
    if (movableState) {
      setFilters({
        token: movableToken,
        isPoolLastDayFilter: false,
        appVersion: APP_VERSIONS.V2,
        status: [POOL_STATUSES.started],
      })
    }
  }, [movableState, movableToken])

  useEffect(() => {
    if (livePools) {
      for (const pool of livePools) {
        queryClient.setQueryData(['pool', pool.address, userAddress], pool)
      }
    }
  }, [livePools, queryClient.setQueryData, userAddress])

  // biome-ignore lint: <explanation>
  useEffect(() => {
    refetch()
  }, [userAddress])

  return (
    <Page>
      <MainContainer>
        {movableState && (
          <Button
            variant={'outline'}
            onClick={() => navigate(-1)}
            leftIcon={<ArrowBackIcon />}
          >
            Back
          </Button>
        )}
        <Flex
          alignItems={'center'}
          justifyContent={'center'}
          gap={'16px'}
          direction={'column'}
          mb={'40px'}
        >
          <Flex
            mt={4}
            direction={{ base: 'column', md: 'row' }}
            alignItems={'center'}
            justifyContent={'center'}
          >
            <Flex alignItems={'center'}>
              <Title
                lineHeight={'normal'}
                mr={movableState ? null : '4px'}
                textAlign={'center'}
                fontWeight={movableState ? 400 : 600}
              >
                {movableState && (
                  <>
                    {t('pools.Choose pool to move')} <br />
                    <Text
                      textDecoration={'underline'}
                      as='span'
                      fontWeight={600}
                    >
                      {formatBalance(movableAmount, 4, false)}{' '}
                      {COIN_SYMBOLS[movableToken]}
                    </Text>
                  </>
                )}
              </Title>
            </Flex>
            {movableState ? null : (
              <Title
                mr={'4px'}
                ml={'14px'}
                lineHeight={'normal'}
                textAlign={'center'}
                fontWeight={600}
              >
                {movableState ? '' : t('pools.Join now and earn rewards!')}
              </Title>
            )}
          </Flex>

          <Text
            fontWeight={300}
            maxW={470}
            textAlign={'center'}
            fontSize={{ base: '16px', md: '18px' }}
          >
            {movableState ? (
              <Trans i18nKey={'pools.Save Time and Cut Down on Fees'}>
                <Text as='span' fontWeight={500}>
                  Save Time and Cut Down on Fees
                </Text>
                <br /> Instantly Transfer Your Deposit to Another
              </Trans>
            ) : (
              t('pools.You can withdraw your full deposit at any time.')
            )}
          </Text>
        </Flex>

        {isLoading && <AbsoluteSpinner />}

        {!isLoading && livePools && (
          <Pools
            pools={livePools}
            userAddress={userAddress}
            refetchPool={refetchPool}
            setFilters={setFilters}
            movableState={
              movableState
                ? {
                    amount: movableAmount,
                    token: movableToken,
                    fromPoolAddress: movablePoolAddress,
                  }
                : null
            }
          />
        )}
      </MainContainer>
    </Page>
  )
}

export default LivePools
