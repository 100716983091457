import { Skeleton, Stack } from '@chakra-ui/react'

export default function MockTableLoader({
  rows = 4,
  isLoaded,
  height = '25px',
}) {
  const elements = Array.from({ length: rows }, (_, index) => index)

  return (
    <Stack>
      {elements?.map((_) => (
        <Skeleton key={_} w={'full'} isLoaded={isLoaded} height={height} />
      ))}
    </Stack>
  )
}
