import { AddressBadge } from '@/components/shared'
import { COINS_ADDRESSES } from '@/constants/coins'
import { getTokenDecimals, getTokenSymbol } from '@/core/pool/common'
import { getAllowance } from '@/core/pool/v2/read'
import { getBalance } from '@/core/user/common'
import { formatBalance } from '@/utils/formatBalance'
import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Tooltip,
} from '@chakra-ui/react'
import { Lock } from '@phosphor-icons/react'
import Big from 'big.js'
import i18n from 'i18next'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { formatEther, formatUnits } from 'viem'
import { useAccount, useGasPrice } from 'wagmi'
import { useQuery } from 'wagmi/query'

const STAKE_GAS_LIMIT = Big(500_000)

const DepositModal = ({
  title = `${i18n.t('pools.Deposit')}`,
  actionText = i18n.t('pools.Deposit'),
  actionLoadingText = `${i18n.t('pools.Depositing')}...`,
  maxValue = '0',
  onSubmit,
  token = COINS_ADDRESSES.FTN,
  onApproveToken,
  poolAddress,
  ...props
}) => {
  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
    watch,
  } = useForm()
  const [isTokenLocked, setIsTokenLocked] = useState(false)
  const [isLoading, toggleLoading] = useState(false)
  const { address } = useAccount()
  const { data: balance, refetch } = useQuery({
    queryKey: ['balance', address, token],
    queryFn: () => getBalance({ address, token }),
    enabled: !!address && !!token,
  })
  const { data: symbol } = useQuery({
    queryKey: ['symbol', token],
    queryFn: () => getTokenSymbol({ token }),
    enabled: !!token,
  })
  const { data: decimals } = useQuery({
    queryKey: ['decimals', token],
    queryFn: () => getTokenDecimals({ token }),
    enabled: !!token,
  })
  const { data: allowance, refetch: refetchAllowance } = useQuery({
    queryKey: ['allowance', address, token],
    queryFn: () =>
      getAllowance({ address, tokenAddress: token, spender: poolAddress }),
    enabled: !!address && !!token && token !== COINS_ADDRESSES.FTN,
  })
  const { data: networkGasPriceInWei } = useGasPrice()
  const { t } = useTranslation()

  const isRtl = document.body.dir === 'rtl'
  const isFTN = token === COINS_ADDRESSES.FTN
  const TokenLockedInputElement = isRtl ? InputLeftElement : InputRightElement

  const handleDepositFormSubmit = async (props) => {
    toggleLoading(true)

    if (isTokenLocked) {
      await onApproveToken({
        toggleLoading,
        decimals,
        refetchAllowance,
        ...props,
      })
    } else {
      await onSubmit({ toggleLoading, decimals, ...props })
    }

    refetch()
  }

  const getMaxValue = () => {
    const userBalance = Big(balance ? formatUnits(balance, decimals) : 0)

    const gasPriceInFtn = Big(
      networkGasPriceInWei ? formatEther(networkGasPriceInWei, 'wei') : Big(0),
    )

    const gasCost = gasPriceInFtn
      ? gasPriceInFtn.times(STAKE_GAS_LIMIT)
      : Big(0)

    const maxAvailableDeposit = isFTN ? userBalance.minus(gasCost) : userBalance

    return maxAvailableDeposit.gt(0) ? maxAvailableDeposit : Big(0)
  }

  const setMaxValue = () => {
    const maxValue = getMaxValue()
    setValue('amount', maxValue ? maxValue.toString() : '', {
      shouldValidate: true,
    })
  }

  const watchAmount = watch('amount')

  useEffect(() => {
    if (token === COINS_ADDRESSES.FTN) {
      return
    }

    const formattedAllowance = allowance ? formatUnits(allowance, decimals) : 0

    const isTokenLocked =
      allowance && Number(formattedAllowance) > 0
        ? Number(watchAmount) > Number(formattedAllowance)
        : true

    setIsTokenLocked(isTokenLocked)
  }, [watchAmount, allowance, token, decimals])

  return (
    <Modal returnFocusOnClose={false} isCentered {...props}>
      <ModalOverlay />
      <ModalContent as='form' onSubmit={handleSubmit(handleDepositFormSubmit)}>
        <ModalHeader>
          {title} {symbol}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={0} mb={'18px'}>
          <Flex
            gap={'8px'}
            mb={'1'}
            direction={'column'}
            alignItems={'flex-start'}
            justifyContent={'spac'}
          >
            {address && (
              <AddressBadge
                withCopy={true}
                address={address || ''}
                symbols={6}
              />
            )}

            <HStack w={'full'} justifyContent={'space-between'}>
              {(balance || (!balance && balance === 0n)) && (
                <Tooltip
                  label={formatUnits(balance, decimals)}
                  placement={'right'}
                >
                  <Text fontWeight={300} fontSize={'16px'}>
                    {symbol} {t('common.Balance')}:{' '}
                    <b>
                      {formatBalance(formatUnits(balance, decimals), 6, false)}{' '}
                      {symbol}
                    </b>
                  </Text>
                </Tooltip>
              )}

              <Button
                variant={'soft'}
                textTransform={'uppercase'}
                onClick={setMaxValue}
                borderRadius={4}
                minHeight={{ base: '28px', md: '30px' }}
                h={'auto'}
              >
                {t('common.Max')}
              </Button>
            </HStack>
          </Flex>

          <FormControl isInvalid={errors.amount ? true : undefined}>
            <InputGroup>
              <Input
                autoFocus={true}
                id={'deposit'}
                placeholder={`${symbol} ${t('common.Amount')}`}
                {...register('amount', {
                  required: t('errors.This is required'),
                  pattern: {
                    value: /^(0|[1-9]\d*)(\.\d+)?$/,
                    message: t('errors.Must be valid number'),
                  },
                  min: {
                    value: '0',
                    message: t('errors.Must be a positive number'),
                  },
                  validate: (value) => {
                    const amount = Big(value)
                    const maxAmount = Big(getMaxValue())
                    const minAmount = Big(0)

                    const maxValueErrorText =
                      amount.gt(maxAmount) &&
                      `${t('errors.Max available deposit is', {
                        amount: maxAmount?.toString(),
                      })} ${symbol}`

                    if (amount.gt(maxAmount)) {
                      return maxValueErrorText
                    }

                    if (amount.lte(minAmount)) {
                      return t('errors.Amount must be greater than 0')
                    }

                    return true
                  },
                })}
                pl={isRtl ? 'var(--input-height)' : 'var(--input-padding)'}
                pr={isRtl ? 'var(--input-padding)' : 'var(--input-height)'}
              />
              {isTokenLocked && (
                <TokenLockedInputElement>
                  <Tooltip label={t('pools.Please approve tokens for deposit')}>
                    <Icon
                      as={Lock}
                      weight='bold'
                      zIndex={5}
                      fontSize={'30px'}
                      bgColor={'orange.100'}
                      color={'orange.600'}
                      p={1}
                      borderRadius={'md'}
                    />
                  </Tooltip>
                </TokenLockedInputElement>
              )}
            </InputGroup>
            <FormErrorMessage>{errors.amount?.message}</FormErrorMessage>
          </FormControl>
        </ModalBody>

        <ModalFooter
          pt={0}
          alignItems={'stretch'}
          flexDirection={'column'}
          gap={0}
        >
          {!isFTN && isTokenLocked && (
            <Text fontSize={'xs'} color={'orange.500'}>
              {t('pools.You need to approve before deposit', { symbol })}
            </Text>
          )}
          <Button
            isLoading={isLoading}
            loadingText={
              isFTN
                ? actionLoadingText
                : isTokenLocked
                  ? t('pools.Approving')
                  : t('pools.Depositing')
            }
            type='submit'
            variant={'action'}
            mb={2}
          >
            {isTokenLocked ? (
              <>{`${t('pools.Please approve for deposit')} (1/2)`}</>
            ) : (
              <>{`${t('pools.Deposit')} ${isFTN ? '' : '(2/2)'}`}</>
            )}
          </Button>
          <Button variant={'soft'} onClick={() => props.onClose()}>
            {t('common.Cancel')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default DepositModal
