import useFinishedPools from './useFinishedPools'
import useLivePools from './useLivePools'

const useMyPools = (userAddress, filters) => {
  const {
    data: livePools = [],
    isLoading: isLoadingLivePools,
    refetch: refetchActivePools,
  } = useLivePools(userAddress, filters, true)

  const {
    data: finishedPools = [],
    isLoading: isLoadingFinishedPools,
    refetch: refetchHistoryPools,
  } = useFinishedPools(userAddress, filters, true)

  const activePools = livePools?.filter(({ userInfo }) => !userInfo.isCashouted)
  const activePoolsForHistory = livePools?.filter(
    ({ userInfo }) => userInfo.isCashouted,
  )

  return {
    data: {
      activePools,
      historyPools: [...activePoolsForHistory, ...finishedPools],
    },
    isLoadingActivePools: isLoadingLivePools,
    isLoadingHistoryPools: isLoadingFinishedPools,
    refetchActivePools,
    refetchHistoryPools,
  }
}

export default useMyPools
