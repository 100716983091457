import { COIN_ICONS } from '@/constants/coins'
import { HStack, Image, Link, Text, Tooltip, VStack } from '@chakra-ui/react'

export default function Funds({
  totalDeposit,
  symbol,
  isFTN,
  token,
  t,
  openTokenInExplorer,
  ...props
}) {
  return (
    <VStack
      alignSelf={'flex-start'}
      alignItems={'flex-start'}
      color={'white'}
      gap={'4px'}
      {...props}
    >
      <Text
        lineHeight={'30px'}
        textTransform={'uppercase'}
        fontWeight={400}
        fontSize={{ base: '20px', sm: '22px', md: '24px' }}
        letterSpacing={'0.8px'}
      >
        {t('pools.Total Deposit')}
      </Text>

      <HStack>
        <Text textTransform={'uppercase'}>{t('pools.Asset')}:</Text>

        <Tooltip
          isDisabled={isFTN}
          label={t('common.View in explorer')}
          placement={'top'}
        >
          <Link textDecoration={'underline'} onClick={openTokenInExplorer}>
            {symbol}
          </Link>
        </Tooltip>
      </HStack>

      <HStack gap={{ base: '8px', md: '16px' }}>
        <Text
          lineHeight={{ base: '40px', md: '50px' }}
          fontWeight={900}
          wordBreak={'break-word'}
          fontSize={{ base: '30px', md: '40px' }}
        >
          {totalDeposit}
        </Text>
        <Image
          src={COIN_ICONS[token]}
          alt={symbol}
          w={{ base: '36px', md: '46px' }}
          h={{ base: '36px', md: '46px' }}
        />
      </HStack>
    </VStack>
  )
}
