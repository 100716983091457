import { config } from '@/configs/wagmi'
import ChakraProvider from '@/providers/ChakraProvider'
import ModalsProvider from '@/providers/ModalsProvider'
import RouterProvider from '@/providers/RouterProvider'
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister'
import { QueryClient } from '@tanstack/react-query'
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client'
import { useTranslation } from 'react-i18next'

import { WagmiProvider, deserialize, serialize } from 'wagmi'

function App() {
  const { i18n } = useTranslation()

  document.body.dir = i18n?.dir?.()

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        gcTime: 1_000 * 60 * 60 * 24, // 24 hours
      },
    },
  })

  const persister = createSyncStoragePersister({
    serialize,
    storage: window.localStorage,
    deserialize,
  })

  return (
    <ChakraProvider>
      <WagmiProvider config={config}>
        <PersistQueryClientProvider
          client={queryClient}
          persistOptions={{ persister }}
        >
          <ModalsProvider>
            <RouterProvider />
          </ModalsProvider>
        </PersistQueryClientProvider>
      </WagmiProvider>
    </ChakraProvider>
  )
}

export default App
