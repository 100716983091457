import { config } from '@/configs/wagmi'
import { APP_VERSIONS } from '@/constants/appVersions'
import { COINS_ADDRESSES } from '@/constants/coins'
import { getBalance as getBalanceWagmi, readContract } from '@wagmi/core'
import { erc20Abi } from 'viem'
import { getHistory as v1getHistory } from './v1/read'
import { getHistory as v2getHistory } from './v2/read'

const ACTIONS = {
  [APP_VERSIONS.V1]: {
    getHistory: v1getHistory,
  },
  [APP_VERSIONS.V2]: {
    getHistory: v2getHistory,
  },
}

export const getHistory = async (
  client,
  {
    poolAddress,
    poolId,
    version,
    userAddress,
    startBlock,
    endBlock,
    appVersion,
    yieldSource,
    decimals,
    token,
  },
) => {
  return ACTIONS[appVersion].getHistory(client, {
    poolAddress,
    poolId,
    version,
    userAddress,
    startBlock,
    endBlock,
    appVersion,
    yieldSource,
    decimals,
    token,
  })
}

export const getBalance = async ({ address, token }) => {
  try {
    if (token === COINS_ADDRESSES.FTN) {
      const balance = await getBalanceWagmi(config, { address })

      return balance.value
    }

    const balance = await readContract(config, {
      abi: erc20Abi,
      address: token,
      functionName: 'balanceOf',
      args: [address],
    })

    return balance
  } catch (err) {
    console.error('Error in getBalance: ', err)
    return null
  }
}
