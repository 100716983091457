import { Tab } from '@chakra-ui/react'

const TabStyled = ({ children, ...props }) => {
  return (
    <Tab
      py={'12px'}
      px={'32px'}
      fontSize={'16px'}
      color={'gray.500'}
      fontWeight={500}
      _selected={{
        bgColor: 'primary.500',
        color: 'white',
      }}
      {...props}
    >
      {children}
    </Tab>
  )
}

export default TabStyled
