import { HStack } from '@chakra-ui/react'
import { Eye, FileText } from '@phosphor-icons/react'
import { Link } from 'react-router-dom'
import { PoolCardIcon, PoolStatusBadge } from '..'

export default function StatusBar({
  isUserPool,
  statusColor,
  statusText,
  poolUrl,
  t,
  onViewPoolInExplorer,
}) {
  return (
    <HStack justifyContent={'space-between'}>
      <PoolStatusBadge statusColor={statusColor} statusText={statusText} />

      <HStack>
        <PoolCardIcon
          label={t('common.View in explorer')}
          onClick={onViewPoolInExplorer}
          Icon={FileText}
          bgColor={'secondary.600'}
        />

        {!isUserPool && (
          <PoolCardIcon
            as={Link}
            to={poolUrl}
            label={t('pools.View Pool')}
            Icon={Eye}
            bgColor={'tertiary.500'}
          />
        )}
      </HStack>
    </HStack>
  )
}
