import {
  bahamut as viemBahamut,
  localhost as viemLocalhost,
} from 'wagmi/chains'

export const bahamut = {
  ...viemBahamut,
  rpcUrls: {
    default: {
      http: ['https://rpc2.bahamut.io', 'https://bahamut.publicnode.com'],
    },
    public: {
      http: ['https://rpc2.bahamut.io', 'https://bahamut.publicnode.com'],
    },
  },
  contracts: {
    registryProxy: {
      address: import.meta.env.VITE_REGISTRY_PROXY_ADDRESS,
    },
  },
}

export const hardhat = {
  ...viemLocalhost,
  id: 31337,
  nativeCurrency: viemBahamut.nativeCurrency,
}

export const supportedChains = {
  [bahamut.id]: bahamut,
  [hardhat.id]: hardhat,
}
