import { Icon as ChakraIcon, Flex, Tooltip } from '@chakra-ui/react'

const PoolCardIcon = ({ Icon, label, ...props }) => {
  return (
    <Flex borderRadius={'50%'} p={2} cursor={'pointer'} {...props}>
      <Tooltip placement={'top'} label={label} isDisabled={!label}>
        <ChakraIcon
          as={Icon}
          weight='bold'
          color={'white'}
          zIndex={5}
          fontSize={'24px'}
        />
      </Tooltip>
    </Flex>
  )
}

export default PoolCardIcon
