import { supportedChains } from '@/configs/chains'
import { COINS_ADDRESSES } from '@/constants/coins'
import { HStack, VStack } from '@chakra-ui/react'
import { openWindow } from '@vaultwin/utils'
import { useChainId } from 'wagmi'
import Dates from './Dates'
import Funds from './Funds'
import Prizes from './Prizes'
import Stats from './Stats'
import WinOrLose from './WinOrLose'

export default function Body({
  totalDeposit,
  accumulatedReward,
  finalReward,
  isWinner,
  isFinished,
  isGameClosed,
  isJoined,
  isWaitingFullfillment,
  winners,
  isUserPool,
  players,
  startDate,
  endDate,
  duration,
  isStarted,
  t,
  token,
  apr,
  symbol,
}) {
  const chainId = useChainId()
  const formattedTotalDeposit = totalDeposit
    ? Number(totalDeposit).toFixed(2)
    : 0

  // const finalPrize = finalReward ? Number(finalReward).toFixed(2) : 0
  const showWinOrLose = isJoined && isFinished && !isWaitingFullfillment
  const isFTN = token === COINS_ADDRESSES.FTN

  const onOpenTokenInExplorer = () => {
    let url

    if (isFTN) {
      url = 'https://www.fasttoken.com/'
    } else {
      const blockExplorerUrl =
        supportedChains[chainId].blockExplorers?.default?.url
      url = `${blockExplorerUrl}/address/${token}`
    }

    openWindow(url)
  }

  return (
    <VStack gap={{ base: '8px', md: '16px' }} alignItems={'stretch'}>
      <HStack
        justifyContent={'space-between'}
        alignItems={{ base: 'flex-start', md: 'stretch' }}
        flexDirection={{ base: 'column', sm: 'row' }}
        gap={1}
      >
        <Dates
          endDate={endDate}
          startDate={startDate}
          duration={duration}
          isStarted={isStarted}
          t={t}
        />

        <Stats apr={apr} players={players} t={t} />
      </HStack>

      <HStack
        alignItems={'stretch'}
        gap={{ base: '12px', md: '24px' }}
        flexDir={{ base: 'column' }}
      >
        <HStack
          alignItems={{ base: 'flex-start' }}
          flexDirection={{ base: 'column', md: 'row' }}
          justifyContent={'space-between'}
        >
          <VStack flex={1} alignItems={'flex-start'}>
            <Funds
              isFTN={isFTN}
              token={token}
              totalDeposit={formattedTotalDeposit}
              t={t}
              symbol={symbol}
              openTokenInExplorer={onOpenTokenInExplorer}
            />

            {isUserPool && showWinOrLose && (
              <Prizes
                alignItems={'flex-start'}
                finalPrize={finalReward}
                accumulatedPrize={accumulatedReward}
                winners={winners}
                isFinished={isFinished}
                isGameClosed={isGameClosed}
                isUserPool={true}
                token={token}
                t={t}
              />
            )}
          </VStack>

          {isUserPool && !showWinOrLose && (
            <Prizes
              alignItems={{ base: 'flex-start', md: 'center' }}
              finalPrize={finalReward}
              accumulatedPrize={accumulatedReward}
              winners={winners}
              isFinished={isFinished}
              isGameClosed={isGameClosed}
              isUserPool={true}
              token={token}
              t={t}
            />
          )}

          {showWinOrLose && (
            <WinOrLose
              isUserPool={isUserPool}
              isWinner={isWinner}
              t={t}
              flex={1}
            />
          )}
        </HStack>

        {!isUserPool && (
          <Prizes
            alignItems={{ base: 'flex-start', md: 'center' }}
            finalPrize={finalReward}
            accumulatedPrize={accumulatedReward}
            winners={winners}
            isFinished={isFinished}
            isGameClosed={isGameClosed}
            token={token}
            t={t}
          />
        )}
      </HStack>
    </VStack>
  )
}
