import { Circle, HStack, Text, VStack } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

const ClockItem = ({ label, value }) => {
  return (
    <VStack gap={0}>
      <Circle
        size={{ base: '50px', sm: '60px', lg: '80px' }}
        fontWeight={500}
        color={'primary.500'}
        borderColor={'primary.500'}
        borderWidth={{ base: '3px', md: '5px' }}
        bgColor={'primary.50'}
        fontSize={{ base: '28px', sm: '36px', lg: '48px' }}
      >
        {value}
      </Circle>
      <Text fontWeight={700} fontSize={{ base: 'sm', sm: 'md' }} color='white'>
        {label}
      </Text>
    </VStack>
  )
}

const calculateTimeLeft = (timestamp) => {
  const difference = +new Date(timestamp) - +new Date()
  let timeLeft = {
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  }

  if (difference > 0) {
    timeLeft = {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / 1000 / 60) % 60),
      seconds: Math.floor((difference / 1000) % 60),
    }
  }

  return timeLeft
}

const Timer = ({ timestamp, onTimerFinish, showDividers = true, ...props }) => {
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(timestamp))
  const { t } = useTranslation()

  useEffect(() => {
    const timer = setInterval(() => {
      const newTimeLeft = calculateTimeLeft(timestamp)
      setTimeLeft(newTimeLeft)

      if (
        newTimeLeft.days === 0 &&
        newTimeLeft.hours === 0 &&
        newTimeLeft.minutes === 0 &&
        newTimeLeft.seconds === 0
      ) {
        clearInterval(timer)
        onTimerFinish?.()
      }
    }, 1000)

    return () => clearInterval(timer)
  }, [timestamp, onTimerFinish])

  return (
    <HStack {...props}>
      <ClockItem label={t('common.Days')} value={timeLeft.days} />
      {showDividers && (
        <Text fontWeight={500} fontSize={'48px'} color={'primary.50'}>
          :
        </Text>
      )}
      <ClockItem label={t('common.Hours')} value={timeLeft.hours} />
      {showDividers && (
        <Text fontWeight={500} fontSize={'48px'} color={'primary.50'}>
          :
        </Text>
      )}
      <ClockItem label={t('common.Minutes')} value={timeLeft.minutes} />
      {showDividers && (
        <Text fontWeight={500} fontSize={'48px'} color={'primary.50'}>
          :
        </Text>
      )}
      <ClockItem label={t('common.Seconds')} value={timeLeft.seconds} />
    </HStack>
  )
}

export default Timer
