import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

const ConfirmModal = ({
  title = 'Confirm Action',
  subtitle = '',
  text = '',
  onActionClick: action,
  actionText,
  actionLoadingText = 'Loading...',
  reverseButtonState = false,
  ...props
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const { t } = useTranslation()

  const handleActionClick = () => {
    setIsLoading(true)

    if (!action) {
      return
    }

    action({ toggleLoading: setIsLoading })
  }

  return (
    <>
      <Modal isCentered {...props}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text fontWeight={500} fontSize={'20px'}>
              {text}
            </Text>
            {subtitle && (
              <Text mt={2} fontWeight={400} fontSize={'16px'}>
                {subtitle}
              </Text>
            )}
          </ModalBody>

          <ModalFooter gap={'8px'}>
            <Button
              isDisabled={!action}
              isLoading={isLoading}
              loadingText={actionLoadingText}
              variant={reverseButtonState ? 'action' : 'soft'}
              onClick={handleActionClick}
            >
              {actionText}
            </Button>
            <Button
              variant={reverseButtonState ? 'soft' : 'action'}
              onClick={() => props.onClose()}
            >
              {t('common.Close')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default ConfirmModal
