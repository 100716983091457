import walletUrls from '@/constants/walletUrls'
import { MetaMaskCircleIcon, WalletConnectCircleIcon } from '@/icons/index'
import {
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
} from '@chakra-ui/react'
import { openWindow } from '@vaultwin/utils'
import { isMobile } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import { ProviderNotFoundError, useConnect } from 'wagmi'

const redirectToMobileMetamaskApp = () => {
  const { host, pathname, search } = window.location
  const pageUrlWithoutProtocol = encodeURI(host + pathname + search)

  openWindow(`${walletUrls.METAMASK}${pageUrlWithoutProtocol}`)
}

const WalletConnectModal = ({ ...props }) => {
  const { connect, connectors } = useConnect()
  const { t } = useTranslation()

  const handleConnectorClick = (connector) => {
    connect(
      { connector },
      {
        onError: (error) => {
          if (isMobile && error instanceof ProviderNotFoundError) {
            if (connector.id === 'metaMask') {
              redirectToMobileMetamaskApp()
            }
          }
        },
      },
    )

    props.onClose()
  }

  const walletIconsMapping = {
    MetaMask: <MetaMaskCircleIcon boxSize={8} />,
    WalletConnect: <WalletConnectCircleIcon boxSize={8} />,
  }

  return (
    <>
      <Modal isCentered {...props}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t('common.Connect Wallet')}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack>
              {connectors?.map((connector) => (
                <HStack
                  as={Button}
                  justifyContent={'flex-start'}
                  flex={1}
                  w={'100%'}
                  key={connector.id}
                  onClick={() => handleConnectorClick(connector)}
                  p={4}
                  rounded={'md'}
                  cursor={'pointer'}
                  boxShadow={'md'}
                  transition={'background 0.2s'}
                >
                  {walletIconsMapping[connector.name]}
                  <Text>{connector.name}</Text>
                </HStack>
              ))}
            </VStack>
          </ModalBody>

          <ModalFooter>
            <Button variant={'outline'} onClick={() => props.onClose()}>
              {t('common.Close')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default WalletConnectModal
