import { LotteryAbi } from '@/abis/LotteryAbi'
import { LotteryAbi_v2 } from '@/abis/LotteryAbi_v2'
import { LotteryAbi_v3 } from '@/abis/LotteryAbi_v3'
import { LotteryAbi_v4 } from '@/abis/LotteryAbi_v4'
import { LotteryAbi_v5 } from '@/abis/LotteryAbi_v5'
import { LotteryAbi_v6 } from '@/abis/LotteryAbi_v6'
import { LotteryAbi_v7 } from '@/abis/LotteryAbi_v7'

const CONTRACT_VERSIONS = {
  V1: 1, // default
  V2: 2, // top-5 winners support
  V3: 3, // Shares support
  V4: 4, // Fixed Shares
  V5: 5, // Added Informative Stake event
  V6: 6, // Added Timestamp to Stake event
  V7: 7, // Fixed finalize function
}

export const CONTRACT_ABI_BY_VERSIONS = {
  [CONTRACT_VERSIONS.V1]: LotteryAbi,
  [CONTRACT_VERSIONS.V2]: LotteryAbi_v2,
  [CONTRACT_VERSIONS.V3]: LotteryAbi_v3,
  [CONTRACT_VERSIONS.V4]: LotteryAbi_v4,
  [CONTRACT_VERSIONS.V5]: LotteryAbi_v5,
  [CONTRACT_VERSIONS.V6]: LotteryAbi_v6,
  [CONTRACT_VERSIONS.V7]: LotteryAbi_v7,
}

export default CONTRACT_VERSIONS
