import { getHistory } from '@/core/user/common'
import { useQuery } from '@tanstack/react-query'

const useHistory = (
  client,
  {
    poolAddress,
    poolId,
    version,
    appVersion,
    userAddress = null,
    startBlock,
    endBlock,
    isJoined = false,
    yieldSource,
    decimals,
    token,
  },
) => {
  return useQuery({
    queryKey: [
      'history',
      poolAddress,
      poolId,
      version,
      userAddress,
      appVersion,
    ],
    queryFn: () =>
      getHistory(client, {
        poolAddress,
        poolId,
        version,
        userAddress,
        startBlock,
        endBlock,
        appVersion,
        yieldSource,
        decimals,
        token,
      }),
    enabled: !!userAddress && !!startBlock && !!endBlock && isJoined && !!token,
  })
}

export default useHistory
