import { Button } from '@chakra-ui/react'
import { useState } from 'react'

const PoolCardAction = ({
  withLoader,
  loadingText,
  action,
  children,
  ...props
}) => {
  const [isLoading, setIsLoading] = useState(false)

  const handleOnClick = () => {
    withLoader && setIsLoading(true)
    action({ toggleButtonLoading: setIsLoading })
  }

  return (
    <Button
      onClick={handleOnClick}
      isLoading={isLoading}
      loadingText={loadingText}
      {...props}
    >
      {children}
    </Button>
  )
}

export default PoolCardAction
