import cupUrl from '@/assets/cup.svg'
import { AddressBadge } from '@/components/shared'
import { COINS_ADDRESSES } from '@/constants/coins'
import { getTokenDecimals, getTokenSymbol } from '@/core/pool/common'
import { getUserWinProbability } from '@/utils/poolUtils'
import {
  Box,
  Center,
  Flex,
  Heading,
  Image,
  Spinner,
  Text,
  VStack,
  useBreakpointValue,
  useColorMode,
} from '@chakra-ui/react'
import { formatUnits } from 'viem'
import { useAccount } from 'wagmi'
import { useQuery } from 'wagmi/query'

const Row = ({ label, children, isMe, colorMode }) => {
  const activeBgColor = colorMode === 'light' ? '#F1EBFF' : '#f1ebff36'

  return (
    <Flex
      gap={{ base: '18px', sm: '36px', md: '48px', xl: '72px' }}
      justifyContent={'space-between'}
      w={'full'}
      direction={'row'}
      bgColor={isMe ? activeBgColor : 'transparent'}
      border={isMe && '1px solid #9364FF'}
      borderBottom={!isMe && '1px solid #E6E8EC'}
      borderRadius={isMe ? '12px' : 'none'}
      p={'12px'}
    >
      <Flex flex={1} alignItems={'center'} gap={0}>
        {label}
      </Flex>
      <Box flex={1} alignItems={'flex-start'}>
        {children}
      </Box>
    </Flex>
  )
}

const PoolLeaderboard = ({
  leaderboard = [],
  isLoading,
  showOnlyWinners,
  t,
  countOfWinners,
  token = COINS_ADDRESSES.FTN,
  ...props
}) => {
  const { address: userAddress } = useAccount()
  const { colorMode } = useColorMode()
  const isMobileSize = useBreakpointValue(
    { base: true, sm: false, md: true, lg: false },
    { ssr: false },
  )
  const { data: decimals } = useQuery({
    queryKey: ['decimals', token],
    queryFn: () => getTokenDecimals({ token }),
    enabled: !!token,
  })
  const { data: symbol } = useQuery({
    queryKey: ['symbol', token],
    queryFn: () => getTokenSymbol({ token }),
    enabled: !!token,
  })

  return (
    <Flex
      direction={'column'}
      p={{ base: '12px', md: '24px' }}
      bgColor={colorMode === 'light' ? 'white' : 'primary.700'}
      borderRadius={'24px'}
      border={'2px solid'}
      borderColor={'primary.500'}
      gap={{ base: 4, md: '36px' }}
      {...props}
    >
      <Flex justifyContent={'center'} alignItems={'center'} mb={0}>
        <Image
          src={cupUrl}
          alt="Winner's cup"
          w={{ base: '76px', md: '106px' }}
          h={{ base: '82px', md: '112px' }}
          mr={'8px'}
        />

        <Heading fontWeight={600} textAlign={'center'} as='h1' fontSize='2xl'>
          {showOnlyWinners ? t('pools.Winners') : t('pools.Top contenders')}
        </Heading>
      </Flex>

      {isLoading ? (
        <Center>
          <Spinner
            color={colorMode === 'light' ? 'black' : 'white'}
            size='xl'
          />
        </Center>
      ) : (
        <VStack
          gap={{ base: '4px', md: '8px' }}
          fontWeight={300}
          fontSize={{ base: '14px', md: '16px' }}
          alignItems={'center'}
          maxH={325}
          overflowY={'auto'}
          overflowX={'hidden'}
        >
          {leaderboard.length === 0 ? (
            <Text>
              {showOnlyWinners
                ? t('pools.Winners Not Found')
                : t('pools.Contenders Not Found')}
            </Text>
          ) : (
            <>
              {leaderboard.map(({ address, chance, deposit }) => (
                <Row
                  key={address}
                  isMe={userAddress === address}
                  colorMode={colorMode}
                  label={
                    <AddressBadge
                      symbols={isMobileSize ? 3 : 6}
                      address={address}
                      withCopy
                    />
                  }
                >
                  <Box>
                    <Text as={'span'} fontWeight={400}>
                      {deposit
                        ? (+formatUnits(deposit, decimals)).toFixed(2)
                        : 0}{' '}
                      {symbol}
                    </Text>{' '}
                    {t('pools.Deposit')}
                  </Box>

                  <Box>
                    <Text as={'span'} fontWeight={400}>
                      {chance
                        ? `${getUserWinProbability({ chance, countOfWinners })?.toFixed(2)}%`
                        : '0%'}
                    </Text>{' '}
                    {t('pools.Win chance')}
                  </Box>
                </Row>
              ))}
            </>
          )}
        </VStack>
      )}
    </Flex>
  )
}

export default PoolLeaderboard
