import { config } from '@/configs/wagmi'
import { COINS_ADDRESSES } from '@/constants/coins'
import { LOLIK_APR, PROTOCOL_FEE, apxSafeStartBlock } from '@/constants/common'
import POOL_STATUSES from '@/constants/poolStatus'
import CONTRACT_VERSIONS, {
  CONTRACT_ABI_BY_VERSIONS,
} from '@/constants/poolVersions'
import oneDayInMilliseconds from '@/constants/times'
import { poolSerializers } from '@/core/serializers/v1'
import {
  getAddressOfUser,
  getCurrentUserIDInPool,
  getUserInfo,
} from '@/core/user/v1/read'
import { removeDuplicatesFromArray } from '@vaultwin/utils'
import { readContract } from '@wagmi/core'
import { formatEther } from 'viem'
import { getBalanceOfPool } from '../common'

export const getPool = async ({ poolAddress, userAddress, metadata }) => {
  const { version } = metadata
  const isProblematicPool =
    poolAddress === '0x8fea7A6c20DbA948B4BE8ea69F581c9a92265e00'

  const currentPoolId = await getCurrentPoolId({
    poolAddress,
    version,
    isProblematicPool,
  })

  const [userInfo, poolInfo, apr] = await Promise.all([
    getUserInfo({
      poolAddress,
      poolId: currentPoolId,
      userAddress,
      version,
    }),
    getPoolInfo({
      poolAddress,
      poolId: currentPoolId,
      version,
    }),
    getAPR(),
  ])

  const safeStartBlock =
    (poolInfo.startBlock && BigInt(poolInfo.startBlock)) || apxSafeStartBlock
  const safeEndBlock =
    (poolInfo.endBlock && BigInt(poolInfo.endBlock)) || 'latest'

  return {
    poolInfo,
    address: poolAddress,
    id: String(currentPoolId),
    userInfo,
    startBlock: safeStartBlock,
    endBlock: safeEndBlock,
    apr,
    ...metadata,
  }
}

export const getCurrentPoolId = async ({
  poolAddress,
  version = CONTRACT_VERSIONS.V1,
  isProblematicPool,
}) => {
  try {
    if (isProblematicPool) return 0n

    const currentPoolId = await readContract(config, {
      abi: CONTRACT_ABI_BY_VERSIONS[version],
      address: poolAddress,
      functionName: 'currentPoolID',
    })

    return currentPoolId - 1n
  } catch (err) {
    console.error('Error in getting current pool ID', err.message)

    return 0n
  }
}

export const getPoolInfo = async ({ poolAddress, poolId, version }) => {
  const isProblematicPool =
    poolAddress === '0x8fea7A6c20DbA948B4BE8ea69F581c9a92265e00'

  const rawPoolInfo = await readContract(config, {
    abi: CONTRACT_ABI_BY_VERSIONS[version],
    address: poolAddress,
    functionName: 'poolInfo',
    args: [poolId],
  })

  const poolInfo = poolSerializers[version](rawPoolInfo)

  const [players, { accumulatedReward, finalReward }] = await Promise.all([
    getTotalActivePlayers({ poolAddress, poolId, version }),
    getPoolPotentialRewards({
      poolAddress,
      totalDeposit: poolInfo.totalDeposit,
      isProblematicPool,
      rewardForRound: poolInfo.rewardForRound,
      start: poolInfo.start,
      duration: poolInfo.duration,
      isFinished: poolInfo.status === POOL_STATUSES.finished,
      version,
    }),
  ])

  return {
    ...poolInfo,
    players: Number(players),
    accumulatedReward,
    finalReward,
  }
}

export const getPoolPotentialRewards = async ({
  poolAddress,
  totalDeposit = 0n,
  rewardForRound = 0n,
  currentTimestamp = BigInt(Date.now()),
  start = 0n,
  duration = 0n,
  version,
  isFinished = false,
}) => {
  try {
    if (isFinished) {
      return { accumulatedReward: rewardForRound, finalReward: rewardForRound }
    }

    const [fee, balance] = await Promise.all([
      getPoolFee({ poolAddress, version }),
      getBalanceOfPool({ poolAddress, tokenAddress: COINS_ADDRESSES.STFTN }),
    ])

    const safeRewardForRound = ((balance - totalDeposit) * (100n - fee)) / 100n

    if (start + duration > currentTimestamp) {
      const daysInYear = 365n
      const lidoRebaseTimeOfDay = 72600000n

      // Calculate the number of days passed in pool time
      const todaysLolikRebase =
        (currentTimestamp / oneDayInMilliseconds) * oneDayInMilliseconds +
        lidoRebaseTimeOfDay

      // Check if the last rebase event has already happened
      let nextRebase
      if (currentTimestamp < todaysLolikRebase) {
        nextRebase = todaysLolikRebase
      } else {
        nextRebase = todaysLolikRebase + oneDayInMilliseconds
      }

      const poolEnd = start + duration
      let remainingRebases = 0n

      while (nextRebase <= poolEnd) {
        remainingRebases++
        nextRebase += oneDayInMilliseconds
      }

      console.log('todaysLolikRebase: ', todaysLolikRebase)
      console.log('remainingRebases: ', remainingRebases)

      return {
        accumulatedReward: safeRewardForRound,
        finalReward:
          safeRewardForRound +
          (((totalDeposit * remainingRebases * LOLIK_APR) /
            (daysInYear * 100n)) *
            (100n - fee)) /
            100n,
      }
    }

    return {
      accumulatedReward: safeRewardForRound,
      finalReward: safeRewardForRound,
    }
  } catch (err) {
    console.error(err.message)

    return {
      accumulatedReward: 0n,
      finalReward: 0n,
    }
  }
}

export const getTotalActivePlayers = async ({
  poolAddress,
  poolId,
  version,
}) => {
  const allPlayers = await getTopPlayers({ poolAddress, poolId, version })

  return allPlayers.filter((player) => player.chance).length
}

export const getWinners = async ({ poolAddress, poolId, version }) => {
  const isProblematicPool =
    poolAddress === '0x23B85682Ad685f94BBc04255Cf92ddA1e98cB5DD' &&
    poolId === '0'

  const winners = isProblematicPool
    ? ['0xa3ec6dfA68D2E2D4e8BEF4A94d6BE01B24Fdc4B3']
    : await readContract(config, {
        functionName: 'getWinners',
        args: [poolId],
        abi: CONTRACT_ABI_BY_VERSIONS[version],
        address: poolAddress,
      })

  const userChancePromises = removeDuplicatesFromArray(winners).map(
    async (address) => {
      try {
        const { chance, depositInPool, finalDeposit } = await getUserInfo({
          poolAddress,
          poolId,
          userAddress: address,
          version,
        })

        const result = {
          address,
          chance: chance ? formatEther(chance) : 0,
          deposit: depositInPool || finalDeposit,
        }

        if (!result.chance || !result.deposit) {
          throw new Error('Failed to get chance and deposit for user')
        }

        return result
      } catch (error) {
        console.error(
          `Failed to get chance for user at address ${address}:`,
          error.message,
        )
        return { address, chance: 0, deposit: 0n }
      }
    },
  )

  const usersWithChance = await Promise.all(userChancePromises)

  return usersWithChance.sort((a, b) => Number(b.chance) - Number(a.chance))
}

export const getTopPlayers = async ({ poolAddress, poolId, version }) => {
  try {
    const lastUserIdInPool = await getCurrentUserIDInPool({
      poolAddress,
      poolId,
      version,
    })

    const userIdsInPool = Array.from(
      { length: Number(lastUserIdInPool) },
      (_, i) => i + 1,
    )

    const userAddressPromises = userIdsInPool.map(async (userId) => {
      try {
        const address = await getAddressOfUser({
          poolAddress,
          poolId,
          userId,
          version,
        })

        return { id: userId, address }
      } catch (error) {
        console.error(
          `Failed to get address for user ${userId}:`,
          error.message,
        )
        return { id: userId, address: null }
      }
    })

    const usersWithAddress = await Promise.all(userAddressPromises)
    const userChancePromises = usersWithAddress.map(async ({ address, id }) => {
      if (!address) {
        throw new Error(`No address for user ${id}`)
      }

      try {
        const { chance, depositInPool, finalDeposit } = await getUserInfo({
          poolAddress,
          poolId,
          userAddress: address,
          version,
        })

        return {
          id,
          address,
          chance: chance ? formatEther(chance) : 0,
          deposit: depositInPool || finalDeposit,
        }
      } catch (error) {
        console.error(error.message)
        return null
      }
    })

    const usersWithChance = await Promise.all(userChancePromises)

    return usersWithChance
      .filter((user) => user)
      .sort((a, b) => Number(b.chance) - Number(a.chance))
  } catch (error) {
    console.error('Failed to get leaderboard:', error.message)
    return []
  }
}

export const getPoolFee = async ({ poolAddress, version }) => {
  try {
    if (version === CONTRACT_VERSIONS.V1) {
      throw new Error('V1 does not have fee')
    }

    const fee = await readContract(config, {
      functionName: 'fee',
      abi: CONTRACT_ABI_BY_VERSIONS[version],
      address: poolAddress,
    })

    return fee
  } catch (err) {
    console.error(err.message)

    return PROTOCOL_FEE
  }
}

export const getAPR = () => {
  return LOLIK_APR ? LOLIK_APR.toString() : 6
}
