import { supportedChains } from '@/configs/chains'
import { formatBalance } from '@/utils/formatBalance'
import { getUserWinProbability } from '@/utils/poolUtils'
import { VStack } from '@chakra-ui/react'
import { openWindow } from '@vaultwin/utils'
import { useChainId } from 'wagmi'
import StatusBar from './StatusBar'
import UserInfo from './UserInfo'

export default function Header({
  statusColor,
  statusText,
  address,
  isJoined,
  deposit,
  isUserPool = false,
  chance,
  isFinished,
  isStarted,
  isNotStarted,
  isPoolLastDay,
  t,
  countOfWinners,
  token,
  isGameClosed,
}) {
  const chainId = useChainId()
  const poolUrl = `/pool/${address}`

  const viewPoolInExplorer = () => {
    const blockExplorerUrl =
      supportedChains[chainId].blockExplorers?.default?.url
    const url = `${blockExplorerUrl}/address/${address}`

    openWindow(url, '_blank')
  }

  const winProbability = getUserWinProbability({ chance, countOfWinners })
  const formattedDeposit = deposit ? Number(deposit).toFixed(2) : 0
  const tooltipChance = winProbability
    ? formatBalance(winProbability, 4, false)
    : 0
  const formattedChance = winProbability
    ? formatBalance(winProbability, 2, false)
    : 0

  return (
    <VStack color={'white'} alignItems={'stretch'} gap={0}>
      <StatusBar
        isUserPool={isUserPool}
        statusColor={statusColor}
        statusText={statusText}
        onViewPoolInExplorer={viewPoolInExplorer}
        poolUrl={poolUrl}
        t={t}
      />

      <UserInfo
        isJoined={isJoined}
        isPoolLastDay={isPoolLastDay}
        deposit={formattedDeposit}
        tooltipChance={tooltipChance}
        chance={formattedChance}
        fullDeposit={deposit}
        isFinished={isFinished}
        isStarted={isStarted}
        isNotStarted={isNotStarted}
        token={token}
        t={t}
        isGameClosed={isGameClosed}
      />
    </VStack>
  )
}
