import { Box, Text } from '@chakra-ui/react'
import { trimAddress } from '@vaultwin/utils'
import React from 'react'

const Address = React.forwardRef(
  (
    { address, symbols = 3, fullAddress = false, linkStyles = {}, ...props },
    ref,
  ) => {
    return (
      <Box overflow={'hidden'} {...props}>
        {fullAddress ? (
          <Text ref={ref} wordBreak={'break-word'} {...props} {...linkStyles}>
            {address}
          </Text>
        ) : (
          <Text ref={ref} {...props} {...linkStyles}>
            {trimAddress(address, symbols)}
          </Text>
        )}
      </Box>
    )
  },
)

export default Address
