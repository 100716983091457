import ftnCoinUrl from '@/assets/images/ftn-small.webp?url'
import stftnCoinUrl from '@/assets/images/stftn.svg?url'
import usdcCoinUrl from '@/assets/images/usdc.webp?url'
import usdtCoinUrl from '@/assets/images/usdt.webp?url'

export const COINS_ADDRESSES = {
  FTN: '0x0000000000000000000000000000000000000000',
  USDT: '0xDeF886C55a79830C47108eeb9c37e78a49684e41',
  USDC: '0x4237e0A5b55233D5B6D6d1D9BF421723954130D8',
  STFTN: '0x780Fb5AcA83F2e3F57EE18cc3094988Ef49D8c3d',
}

export const COIN_ICONS = {
  [COINS_ADDRESSES.FTN]: ftnCoinUrl,
  [COINS_ADDRESSES.USDT]: usdtCoinUrl,
  [COINS_ADDRESSES.USDC]: usdcCoinUrl,
  [COINS_ADDRESSES.STFTN]: stftnCoinUrl,
}

export const COIN_SYMBOLS = {
  [COINS_ADDRESSES.FTN]: 'FTN',
  [COINS_ADDRESSES.USDT]: 'USDT',
  [COINS_ADDRESSES.USDC]: 'USDC',
  [COINS_ADDRESSES.STFTN]: 'stFTN',
}
