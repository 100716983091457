import { getLeaderboard } from '@/core/pool/common'
import { useQuery } from '@tanstack/react-query'

const useLeaderboard = ({
  poolAddress,
  poolId,
  version,
  onlyWinners = false,
  limit = null,
  appVersion,
  yieldSource,
  token,
}) => {
  return useQuery({
    enabled:
      !!poolAddress && !!version && !!appVersion && !!token && !!yieldSource,
    queryKey: [
      'leaderboard',
      poolAddress,
      poolId,
      version,
      onlyWinners,
      appVersion,
    ],
    queryFn: () =>
      getLeaderboard({
        poolAddress,
        poolId,
        version,
        onlyWinners,
        appVersion,
        yieldSource,
        token,
      }),
    select: (data) => {
      if (onlyWinners && data.find((item) => item.chance === 0)) {
        return []
      }

      return data.filter((item) => item.chance).slice(0, limit || data.length)
    },
  })
}

export default useLeaderboard
