import { Dashes } from '@/components/shared'
import { APP_VERSIONS } from '@/constants/appVersions'
import { COINS_ADDRESSES } from '@/constants/coins'
import MODALS from '@/constants/modals'
import POOL_STATUSES from '@/constants/poolStatus'
import {
  claimAction,
  getTokenDecimals,
  getTokenSymbol,
  stakeAction,
  unstakeAction,
} from '@/core/pool/common'
import { finalizePoolAction } from '@/core/pool/v1/write'
import { approveERC20Action, moveDepositAction } from '@/core/pool/v2/write'
import useModals from '@/hooks/useModals'
import { formatBalance } from '@/utils/formatBalance'
import {
  formatPoolDates,
  formatRewards,
  getPoolStatusData,
  getUserInfo,
  isPoolLastDayState,
  isWaitingFulfilmentState,
} from '@/utils/poolUtils'
import { useToast } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { etherUnits, formatUnits, parseUnits } from 'viem'
import { useAccount, useChainId } from 'wagmi'
import { useQuery } from 'wagmi/query'
import {
  PoolCardBody,
  PoolCardContainer,
  PoolCardFooter,
  PoolCardHeader,
} from './poolCard'

const Pool = ({
  poolInfo = {},
  endBlock,
  id,
  address,
  version,
  appVersion,
  userInfo,
  userAddress,
  refetchPool,
  isUserPool = false,
  yieldSource,
  token = COINS_ADDRESSES.FTN,
  apr,
  movableState,
  ...props
}) => {
  const { openModal, closeModal } = useModals()
  const { isConnected, chainId: userChainId } = useAccount()
  const chainId = useChainId()
  const toast = useToast()
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { data: decimals } = useQuery({
    queryKey: ['decimals', token],
    queryFn: () => getTokenDecimals({ token }),
    enabled: !!token,
  })
  const { data: symbol } = useQuery({
    queryKey: ['symbol', token],
    queryFn: () => getTokenSymbol({ token }),
    enabled: !!token,
  })

  const onJoinPool = (props) => {
    openModal(MODALS.Deposit, {
      token,
      poolAddress: address,
      onApproveToken: async ({
        toggleLoading,
        amount,
        refetchAllowance,
        decimals = etherUnits.wei,
      }) => {
        const result = await approveERC20Action({
          poolAddress: address,
          tokenAddress: token,
          value: parseUnits(amount, decimals),
          toast,
          appVersion,
          version,
          yieldSource,
        })

        if (result?.status === 'success') {
          toast({
            title: t('common.Success'),
            description: t('pools.Successfully approved tokens'),
            status: 'success',
            duration: 9000,
            isClosable: true,
          })
        }

        refetchPool()
        refetchAllowance()
        toggleLoading(false)
      },
      onSubmit: async ({
        toggleLoading,
        amount,
        decimals = etherUnits.wei,
      }) => {
        const result = await stakeAction({
          poolAddress: address,
          value: parseUnits(amount, decimals),
          toast,
          appVersion,
          version,
          yieldSource,
          token,
        })

        if (result?.status === 'success') {
          toast({
            title: t('common.Success'),
            description: t('pools.Successfully joined to pool'),
            status: 'success',
            duration: 9000,
            isClosable: true,
          })
        }

        refetchPool()
        closeModal(MODALS.Deposit)
        toggleLoading(false)
      },
      ...props,
    })
  }

  const onBoost = () => {
    onJoinPool({
      title: t('pools.Boost chance'),
      actionText: t('pools.Boost'),
      actionLoadingText: `${t('pools.Boosting')}...`,
      onSubmit: async ({ toggleLoading, amount }) => {
        const result = await stakeAction({
          poolAddress: address,
          value: parseUnits(amount, decimals),
          toast,
          appVersion,
          version,
          yieldSource,
          token,
        })

        if (result?.status) {
          toast({
            title: t('pools.Chances are boosted'),
            status: 'success',
            duration: 5000,
            isClosable: true,
          })
        }

        refetchPool()
        closeModal(MODALS.Deposit)
        toggleLoading(false)
      },
    })
  }

  const onCashout = async ({ toggleButtonLoading = () => {} }) => {
    if (status === POOL_STATUSES.finished) {
      const result = await unstakeAction({
        poolAddress: address,
        toast,
        version,
        appVersion,
        yieldSource,
        token,
      })

      if (result?.status) {
        toast({
          title: t('pools.Cashed out successfully'),
          status: 'success',
          duration: 5000,
          isClosable: true,
        })
      }

      refetchPool()
      toggleButtonLoading(false)
    } else {
      openModal(MODALS.Confirm, {
        title: t('pools.Cashout deposit'),
        text: t(
          'pools.Are you sure you want to lose your chance to win the prize?',
        ),
        actionText: t('pools.Cashout'),
        actionLoadingText: `${t('pools.Cashing out')}...`,
        onActionClick: async ({ toggleLoading }) => {
          const result = await unstakeAction({
            poolAddress: address,
            toast,
            version,
            appVersion,
            yieldSource,
            token,
          })

          if (result?.status) {
            toast({
              title: t('pools.Cashed out successfully'),
              status: 'success',
              duration: 5000,
              isClosable: true,
            })
          }

          refetchPool()
          closeModal(MODALS.Confirm)
          toggleLoading(false)
        },
      })
    }
  }

  const onClaim = async ({ toggleButtonLoading = () => {} }) => {
    const result = await claimAction({
      poolAddress: address,
      toast,
      appVersion,
      version,
      yieldSource,
      token,
    })

    console.log('Claim action result: ', result)
    if (result?.status) {
      toast({
        title: t('pools.Claim successful'),
        status: 'success',
        duration: 5000,
        isClosable: true,
      })
    }

    refetchPool()
    toggleButtonLoading(false)
  }

  const onFinalize = async ({ toggleButtonLoading = () => {} }) => {
    const result = await finalizePoolAction({
      poolAddress: address,
      version,
      toast,
      token,
    })

    console.log('Finalize action result: ', result)
    if (result?.status) {
      toast({
        title: t('pools.Finalized successfully'),
        status: 'success',
        duration: 5000,
        isClosable: true,
      })
    }

    refetchPool()
    toggleButtonLoading(false)
  }

  const onMoveDeposit = () => {
    if (movableState) {
      openModal(MODALS.Confirm, {
        title: t('pools.Move Deposit'),
        text: `${t('pools.Are you sure you want to move')} ${formatBalance(movableState.amount, 4, false)} ${symbol}?`,
        actionText: t('common.Confirm'),
        actionLoadingText: `${t('common.Confirming')}...`,
        reverseButtonState: true,
        onActionClick: async ({ toggleLoading }) => {
          const result = await moveDepositAction({
            poolAddress: movableState.fromPoolAddress,
            toast,
            appVersion: APP_VERSIONS.V2,
            version,
            yieldSource,
            token,
            poolAddressToMove: address,
          })

          if (result?.status) {
            toast({
              title: t('pools.Deposit moved successfully'),
              status: 'success',
              duration: 5000,
              isClosable: true,
            })

            navigate(`/pool/${address}`)
          }

          refetchPool()
          closeModal(MODALS.Confirm)
          toggleLoading(false)
        },
      })
    } else {
      const userDeposit = Number(userStats.deposit)
      const countOfWinners = poolInfo.countOfWinners || 1
      const rewardPerNumber = finalReward / countOfWinners
      const realWinningNumbers = userInfo.winningNumbers.filter(
        (number) => number,
      )
      const userReward = rewardPerNumber * realWinningNumbers.length
      const amount = userDeposit + userReward

      navigate('/', {
        state: {
          amount,
          movableState: true,
          token,
          poolAddress: address,
        },
      })
    }
  }

  const handleTimerFinish = () => {
    refetchPool()
  }

  const { status, statusColor, statusText } = getPoolStatusData({
    status: poolInfo.status,
    end: poolInfo.end,
  })
  const { startDate, endDate, duration } = formatPoolDates({
    start: poolInfo.start,
    end: poolInfo.end,
    duration: poolInfo.duration,
  })
  const { accumulatedReward, finalReward } = formatRewards({
    accumulatedReward: poolInfo.accumulatedReward,
    finalReward: poolInfo.finalReward,
    decimals,
  })

  const isWaitingFulfilment = isWaitingFulfilmentState({
    status,
    winningNumbers: userInfo.winningNumbers,
    isNewAppVersion: appVersion === APP_VERSIONS.V2,
    endBlock,
  })
  const userStats = getUserInfo({
    status,
    winningNumbers: userInfo.winningNumbers,
    isJoined: userInfo.isJoined,
    chance: userInfo.chance,
    finalDeposit: userInfo.finalDeposit,
    depositInPool: userInfo.depositInPool,
    decimals,
    token,
    isNewAppVersion: appVersion === APP_VERSIONS.V2,
  })
  const isPoolLastDay = isPoolLastDayState({ status, end: poolInfo.end })
  const totalDeposit = formatUnits(poolInfo.totalDeposit, decimals)
  const timerTimestamp =
    status === POOL_STATUSES.started ? poolInfo.end : poolInfo.start

  return (
    <PoolCardContainer {...props}>
      <PoolCardHeader
        statusColor={statusColor}
        statusText={t(statusText)}
        address={address}
        isJoined={userInfo.isJoined}
        deposit={userStats.deposit}
        isUserPool={isUserPool}
        chance={userStats.userWinChance}
        isFinished={status === POOL_STATUSES.finished}
        isStarted={status === POOL_STATUSES.started}
        isNotStarted={status === POOL_STATUSES.notStarted}
        isPoolLastDay={isPoolLastDay}
        t={t}
        countOfWinners={poolInfo.countOfWinners}
        token={token}
        isGameClosed={status === POOL_STATUSES.gameClosed}
      />

      <Dashes flex={0} mt={'24px'} mb={'16px'} />

      <PoolCardBody
        totalDeposit={totalDeposit}
        accumulatedReward={accumulatedReward}
        finalReward={finalReward}
        players={poolInfo.players}
        isWinner={userStats.isWinner}
        isStarted={status === POOL_STATUSES.started}
        isFinished={status === POOL_STATUSES.finished}
        isGameClosed={status === POOL_STATUSES.gameClosed}
        isWaitingFullfillment={isWaitingFulfilment}
        isJoined={userInfo.isJoined}
        winners={poolInfo.countOfWinners}
        startDate={startDate}
        endDate={endDate}
        duration={duration}
        isUserPool={isUserPool}
        winningNumbers={userStats.winningNumbers}
        t={t}
        token={token}
        apr={apr}
        symbol={symbol}
      />

      <Dashes my={'16px'} />

      <PoolCardFooter
        onBoost={onBoost}
        onCashout={onCashout}
        isWinner={userStats.isWinner}
        onFinalize={onFinalize}
        onClaim={onClaim}
        onMoveDeposit={onMoveDeposit}
        isClaimed={userInfo.isClaimed}
        status={status}
        timerTimestamp={timerTimestamp}
        onTimerFinish={handleTimerFinish}
        isCashouted={userInfo.isCashouted}
        isConnected={isConnected}
        isCorrectChain={userChainId === chainId}
        isJoined={userInfo.isJoined}
        isWaitingFullfillment={isWaitingFulfilment}
        isPoolLastDay={isPoolLastDay}
        onJoinPool={onJoinPool}
        appVersion={appVersion}
        movableState={movableState}
      />
    </PoolCardContainer>
  )
}

export default Pool
