const POOL_STATUSES = {
  notStarted: 'notStarted',
  started: 'started',
  gameClosed: 'gameClosed',
  finished: 'finished',
}

export const POOL_STATUSES_CONTRACT = {
  0: POOL_STATUSES.notStarted,
  1: POOL_STATUSES.started,
  2: POOL_STATUSES.finished,
}

export const POOL_STATUS_COLORS = {
  [POOL_STATUSES.notStarted]: 'orange',
  [POOL_STATUSES.started]: 'green',
  [POOL_STATUSES.gameClosed]: 'purple',
  [POOL_STATUSES.finished]: 'red',
}

export const POOL_STATUS_TEXTS = {
  [POOL_STATUSES.notStarted]: 'pools.Not Started',
  [POOL_STATUSES.started]: 'pools.Started',
  [POOL_STATUSES.gameClosed]: 'pools.Game Closed',
  [POOL_STATUSES.finished]: 'pools.Finished',
}

export default POOL_STATUSES
