import VwLogoUrl from '@/assets/vw-logo.svg'
import { MoonIcon, SunIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  HStack,
  Icon,
  Image,
  Text,
  VStack,
  useColorMode,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react'
import { List } from '@phosphor-icons/react'
import {
  GetNotifiedButton,
  LanguageDropdown,
  MainContainer,
} from '@vaultwin/ui'
import { APPS, getBaseUrl } from '@vaultwin/utils'
import { useTranslation } from 'react-i18next'
import { Link as RouterLink, useLocation } from 'react-router-dom'
import { useAccount, useDisconnect } from 'wagmi'
import { AddressBadge, WalletConnectButton } from './shared'

const currentApp = APPS.APP

const DrawerMenuItem = ({ children, to, app, ...props }) => {
  return (
    <Button
      as={RouterLink}
      my={1}
      transition={'all 0.3s ease'}
      fontSize={'14px'}
      fontWeight={400}
      size={'md'}
      minHeight={'40px'}
      minH={'inherit'}
      to={app && app !== currentApp ? getBaseUrl(app, to) : to}
      target={app && app !== currentApp ? '_blank' : '_self'}
      {...props}
    >
      {children}
    </Button>
  )
}

const MobileNavbar = ({ menuItems = [] }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { colorMode, toggleColorMode } = useColorMode()
  const { address, connector } = useAccount()
  const { disconnect } = useDisconnect()
  const { pathname } = useLocation()
  const { t } = useTranslation()
  const isRtl = document.body.dir === 'rtl'

  return (
    <Box
      position={'sticky'}
      filter='auto'
      left={0}
      top={0}
      py={'20px'}
      zIndex={5}
      _before={{
        content: `""`,
        display: 'block',
        position: 'absolute',
        pointerEvents: 'none',
        left: 0,
        top: 0,
        zIndex: 0,
        width: '100%',
        height: '100%',
        backgroundColor: useColorModeValue(
          'rgb(241 235 255 / 30%)',
          'rgb(0 0 0 / 30%)',
        ),
        backdropFilter: 'blur(6px)',
      }}
    >
      <MainContainer>
        <HStack justifyContent={'space-between'}>
          <Box zIndex={2} as={RouterLink} to={'/'} cursor={'pointer'}>
            <Image w={{ base: 100, lg: 150 }} src={VwLogoUrl} alt='VW Logo' />
          </Box>

          <Icon
            cursor={'pointer'}
            onClick={onOpen}
            as={List}
            weight='bold'
            fontSize={'32px'}
            color={useColorModeValue('primary.500', 'primary.50')}
            zIndex={5}
          />

          <Drawer
            size={'xs'}
            placement={isRtl ? 'left' : 'right'}
            onClose={onClose}
            isOpen={isOpen}
          >
            <DrawerOverlay />
            <DrawerContent pt={10}>
              <DrawerCloseButton />
              <DrawerBody as={VStack} alignItems={'flex-start'} spacing={8}>
                <Flex direction={'column'} w={'full'}>
                  {menuItems.map((item) =>
                    item.secure && !address ? null : (
                      <DrawerMenuItem
                        isActive={pathname === item.path}
                        to={item.path}
                        key={item.label}
                        app={item.app}
                      >
                        {t(`menu.${item.label}`)}
                      </DrawerMenuItem>
                    ),
                  )}
                </Flex>

                {address ? (
                  <VStack w={'full'} alignItems={'stretch'} gap={1}>
                    <Text fontSize={'small'}>
                      {t('common.Connected via', {
                        connector: connector?.name,
                      })}
                    </Text>

                    <AddressBadge
                      py={1}
                      withCopy={true}
                      symbols={8}
                      address={address}
                    />

                    <Button
                      variant={
                        colorMode === 'light' ? 'outline' : 'outlineWhite'
                      }
                      onClick={() => {
                        disconnect()
                      }}
                    >
                      {t('common.Disconnect')}
                    </Button>
                  </VStack>
                ) : (
                  <WalletConnectButton w={'full'} />
                )}

                <HStack
                  w={'full'}
                  alignItems={'flex-end'}
                  justifyContent={'space-between'}
                  spacing={1}
                >
                  <LanguageDropdown />

                  <Flex gap={1}>
                    <Button
                      width={{ base: '24px', md: '48px' }}
                      onClick={toggleColorMode}
                      _light={{
                        bgColor: 'whiteAlpha.300',
                        _hover: { bgColor: 'whiteAlpha.500' },
                      }}
                    >
                      {colorMode === 'light' ? <MoonIcon /> : <SunIcon />}
                    </Button>

                    <GetNotifiedButton />
                  </Flex>
                </HStack>
              </DrawerBody>
            </DrawerContent>
          </Drawer>
        </HStack>
      </MainContainer>
    </Box>
  )
}

export default MobileNavbar
