import { Spacer } from '@chakra-ui/react'

const Dashes = ({ ...props }) => {
  return (
    <Spacer
      minH={'1px'}
      style={{
        background:
          'linear-gradient(to right, white 50%, rgba(255, 255, 255, 0) 0%) bottom / 25px 1px repeat-x',
      }}
      {...props}
    />
  )
}

export default Dashes
