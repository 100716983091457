import { Heading, useColorModeValue } from '@chakra-ui/react'

const Title = ({ children, ...props }) => {
  return (
    <Heading
      fontWeight={600}
      fontSize={['30px', '40px', '48px', '56px']}
      color={useColorModeValue('gray.900', 'gray.100')}
      {...props}
    >
      {children}
    </Heading>
  )
}

export default Title
