const timestampToSeconds = (timestamp) => {
  try {
    if (typeof timestamp !== 'bigint') {
      throw new Error('timestamp must be a bigint')
    }

    return timestamp / 1000n
  } catch (error) {
    console.log('Failed to convert timestamp to seconds:', error.message)
    return 0n
  }
}

export default timestampToSeconds
