const findActivePoolComponent = (statesObj) => {
  for (const key in statesObj) {
    if (statesObj[key].condition) {
      if (statesObj[key].component) {
        return statesObj[key].component
      }
      if (statesObj[key].states) {
        return findActivePoolComponent(statesObj[key].states)
      }
    }
  }
  return null
}

export default findActivePoolComponent
