import { formatEther } from 'viem'
import { BigDecimal } from './big'

export const formatBalance = (
  balance = 0,
  maxDecimalDigits = 2,
  toFtn = true,
) => {
  const safeBalance = BigDecimal(balance)
  const balanceString = toFtn
    ? formatEther(safeBalance ? BigInt(safeBalance.toString()) : 0n)
    : safeBalance.toString()

  if (maxDecimalDigits > 0 && balanceString.includes('.')) {
    const [integerPart, decimalPart] = balanceString.split('.')
    const formattedIntegerPart = Number.parseInt(
      integerPart,
      10,
    ).toLocaleString()
    return `${formattedIntegerPart}.${decimalPart.slice(0, maxDecimalDigits)}`
  }

  return Number.parseInt(balanceString, 10).toLocaleString()
}
