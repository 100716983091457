import POOL_STATUSES, {
  POOL_STATUS_COLORS,
  POOL_STATUS_TEXTS,
} from '@/constants/poolStatus'
import oneDayInMilliseconds from '@/constants/times'
import formatDuration from '@/utils/formatDuration'
import formatTimestampToDate from '@/utils/formatTimestampToDate'
import timestampToSeconds from '@/utils/timestampToSeconds'
import { etherUnits, formatEther, formatUnits } from 'viem'

// TODO: add jsdoc for all functions
// /**
//  * Determines the status of a pool based on its current status, end time, and current timestamp.
//  *
//  * @param {Object} params - The parameters for determining the pool status.
//  * @param {POOL_STATUSES_CONTRACT} params.status - The current status of the pool.
//  * @param {bigint} params.end - The end time of the pool as a bigint.
//  * @param {number} [params.currentTimestamp=Date.now()] - The current timestamp, defaults to the current date and time.
//  *
//  * @returns {POOL_STATUSES} - Returns the updated status of the pool.
//  */
export const getPoolStatusData = ({
  status = null,
  end = 0n,
  currentTimestamp = Date.now(),
  t,
}) => {
  const isGameClosed =
    status === POOL_STATUSES.started && end < BigInt(currentTimestamp)

  const updatedStatus = isGameClosed ? POOL_STATUSES.gameClosed : status

  return {
    status: updatedStatus,
    statusColor: POOL_STATUS_COLORS[updatedStatus],
    statusText: POOL_STATUS_TEXTS[updatedStatus],
  }
}

/**
 * Formats the start and end dates, and the duration of a pool.
 *
 * @param {Object} params - The pool information containing timestamps and duration.
 * @param {bigint} params.start - The start time of the pool as a bigint or a value convertible to a number.
 * @param {bigint} params.end - The end time of the pool as a bigint or a value convertible to a number.
 * @param {bigint} params.duration - The duration of the pool as a bigint or a value convertible to a number.
 *
 * @returns {Object} - An object containing formatted start date, end date, and duration.
 * @returns {string} return.startDate - The formatted start date.
 * @returns {string} return.endDate - The formatted end date.
 * @returns {string} return.duration - The formatted duration.
 */
export const formatPoolDates = ({ start = 0n, end = 0n, duration = 0n }) => {
  return {
    startDate: formatTimestampToDate(Number(start)),
    endDate: formatTimestampToDate(Number(end)),
    duration: formatDuration(Number(timestampToSeconds(duration))),
  }
}

/**
 * Formats the reward for a round or the potential reward into ftn.
 *
 * @param {Object} params - The reward parameters.
 * @param {bigint} [params.rewardForRound=0n] - The reward for the current round as a bigint.
 * @param {bigint} [params.potentialReward=0n] - The potential reward as a bigint.
 *
 * @returns {string} - The formatted reward in ether.
 */
export const formatRewards = ({
  accumulatedReward = 0n,
  finalReward = 0n,
  decimals = etherUnits.wei,
}) => {
  return {
    accumulatedReward: formatUnits(accumulatedReward, decimals),
    finalReward: formatUnits(finalReward, decimals),
  }
}

// TODO: maybe move logic to serializers?
export const getUserInfo = ({
  status,
  winningNumbers = [],
  isJoined,
  chance = 0n,
  finalDeposit = 0n,
  depositInPool = 0n,
  decimals = etherUnits.wei,
  isNewAppVersion = false,
}) => {
  const userWinChance =
    status !== POOL_STATUSES.finished && isJoined ? formatEther(chance) : 0

  if (isNewAppVersion) {
    const isWinner = winningNumbers?.length > 0

    return {
      isWinner: status === POOL_STATUSES.finished ? isWinner : null,
      userWinChance,
      deposit: isJoined
        ? formatUnits(finalDeposit || depositInPool, decimals)
        : 0,
    }
  }

  const isWinner = winningNumbers?.length > 0 && !winningNumbers.includes(0n)

  return {
    isWinner: status === POOL_STATUSES.finished ? isWinner : null,
    userWinChance,
    deposit: isJoined
      ? formatUnits(finalDeposit || depositInPool, decimals)
      : 0,
  }
}

export const getUserWinProbability = ({ chance = 0, countOfWinners = 1 }) => {
  const numericChance = Number(chance)
  const probabilityToWin = 1 - (1 - numericChance) ** countOfWinners

  return probabilityToWin * 100
}

export const isWaitingFulfilmentState = ({
  status,
  winningNumbers = [],
  isNewAppVersion = false,
  endBlock,
}) => {
  if (isNewAppVersion) {
    return (
      status === POOL_STATUSES.finished && (!endBlock || endBlock === 'latest')
    )
  }

  return (
    (status === POOL_STATUSES.finished &&
      winningNumbers &&
      winningNumbers.length > 0 &&
      winningNumbers.includes(0n)) ||
    false
  )
}

export const isPoolLastDayState = ({
  status,
  end = 0n,
  currentTimestamp = Date.now(),
}) => {
  const poolLastDayStart = end ? BigInt(end) - oneDayInMilliseconds : 0n

  return (
    status === POOL_STATUSES.started &&
    currentTimestamp > poolLastDayStart &&
    currentTimestamp < end
  )
}
