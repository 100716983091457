import MODALS from '@/constants/modals'
import useModals from '@/hooks/useModals'
import { Button, Tooltip } from '@chakra-ui/react'
import { Footer as SharedFooter } from '@vaultwin/ui'
import { useTranslation } from 'react-i18next'
import { useAccount, useChainId, useSwitchChain } from 'wagmi'

const Footer = ({ ...props }) => {
  const { t } = useTranslation()
  const { switchChain } = useSwitchChain()
  const { connector, chainId: userChainId, isConnected } = useAccount()
  const chainId = useChainId()
  const { openModal } = useModals()

  const isCorrectChain = isConnected && chainId === userChainId

  const addBahamut = () => {
    switchChain({ chainId, connector })
  }

  return (
    <SharedFooter {...props}>
      <Tooltip
        isDisabled={!isCorrectChain}
        label={t('common.Your are already connected')}
      >
        <Button
          isDisabled={isCorrectChain}
          variant={'action'}
          onClick={
            isConnected ? addBahamut : () => openModal(MODALS.WalletConnect)
          }
        >
          {t('common.Add Bahamut')}
        </Button>
      </Tooltip>
    </SharedFooter>
  )
}

export default Footer
