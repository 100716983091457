import { LotteryAbi } from '@/abis/LotteryAbi'
import { config } from '@/configs/wagmi'
import { CONTRACT_ABI_BY_VERSIONS } from '@/constants/poolVersions'
import { waitForTransactionReceipt, writeContract } from '@wagmi/core'
import i18n from 'i18next'
import { TransactionExecutionError } from 'viem'

export const stakeAction = async ({ poolAddress, value, toast }) => {
  try {
    const hash = await writeContract(config, {
      abi: LotteryAbi,
      address: poolAddress,
      functionName: 'stake',
      value,
      gas: 500_000n,
    })

    if (!hash) {
      throw new Error(i18n.t('errors.Transaction failed'))
    }

    const result = await waitForTransactionReceipt(config, { hash })

    if (!result || result.status !== 'success') {
      throw new Error(i18n.t('errors.Transaction failed'))
    }

    return result
  } catch (error) {
    console.error('Error in stakeAction: ', error.message)

    if (error instanceof TransactionExecutionError) {
      return
    }

    toast({
      title: i18n.t('errors.Transaction failed'),
      status: 'error',
      duration: 5000,
      isClosable: true,
    })
  }
}

export const finalizePoolAction = async ({ poolAddress, version, toast }) => {
  try {
    const hash = await writeContract(config, {
      functionName: 'finalizeRound',
      abi: CONTRACT_ABI_BY_VERSIONS[version],
      address: poolAddress,
      args: [],
    })

    if (!hash) {
      throw new Error(i18n.t('errors.Transaction failed'))
    }

    const result = await waitForTransactionReceipt(config, { hash })

    if (!result || result.status !== 'success') {
      throw new Error(i18n.t('errors.Transaction failed'))
    }

    return result
  } catch (error) {
    console.error('Error in finalizeActions: ', error)

    if (error instanceof TransactionExecutionError) {
      return
    }

    toast({
      title: i18n.t('errors.Transaction failed'),
      status: 'error',
      duration: 5000,
      isClosable: true,
    })
  }
}

export const unstakeAction = async ({
  poolAddress,
  poolId,
  toast,
  version,
}) => {
  try {
    const args = [BigInt(poolId)]

    const hash = await writeContract(config, {
      abi: CONTRACT_ABI_BY_VERSIONS[version],
      address: poolAddress,
      functionName: 'unstake',
      args,
    })

    if (!hash) {
      throw new Error(i18n.t('errors.Transaction failed'))
    }

    const result = await waitForTransactionReceipt(config, { hash })

    if (!result || result.status !== 'success') {
      throw new Error(i18n.t('errors.Transaction failed'))
    }

    return result
  } catch (error) {
    console.error('Error in unstakeAction: ', error.message)

    if (error instanceof TransactionExecutionError) {
      return
    }

    toast({
      title: i18n.t('errors.Transaction failed'),
      description: error.message,
      status: 'error',
      duration: 5000,
      isClosable: true,
    })
  }
}

export const claimAction = async ({ poolAddress, poolId, toast, version }) => {
  try {
    const hash = await writeContract(config, {
      abi: CONTRACT_ABI_BY_VERSIONS[version],
      address: poolAddress,
      functionName: 'claim',
      args: [poolId],
    })

    if (!hash) {
      throw new Error(i18n.t('errors.Transaction failed'))
    }

    const result = await waitForTransactionReceipt(config, { hash })

    if (!result || result.status !== 'success') {
      throw new Error(i18n.t('errors.Transaction failed'))
    }

    return result
  } catch (error) {
    console.error('Error in claimAction: ', error)

    if (error instanceof TransactionExecutionError) {
      return
    }

    toast({
      title: i18n.t('errors.Transaction failed'),
      status: 'error',
      duration: 5000,
      isClosable: true,
    })
  }
}
