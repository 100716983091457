import MobileNavbar from '@/components/MobileNavbar'
import Navbar from '@/components/Navbar'
import { Box, Flex, useBreakpointValue, useMediaQuery } from '@chakra-ui/react'
import bgTopGradientUrl from '/bg-top-gradient.svg'
import Footer from './Footer'

const menuItems = [
  { label: 'Live Pools', path: '/' },
  { label: 'Finished Pools', path: '/finished-pools' },
  { label: 'My Pools', path: '/my-pools', secure: true },
]

const Page = ({ children, withGradient = true, ...props }) => {
  const [moreThan1920] = useMediaQuery('(min-width: 1921px)', { ssr: false })
  const isMobile = useBreakpointValue(
    { base: true, sm: true, md: false },
    { ssr: false },
  )

  return (
    <Flex
      flexDirection={'column'}
      alignItems={'stretch'}
      position={'relative'}
      overflowX={'hidden'}
      w={'full'}
      h={'100%'}
      _before={
        withGradient
          ? {
              content: `''`,
              position: 'absolute',
              left: 0,
              top: 0,
              width: '100%',
              height: '100%',
              backgroundImage: `url(${bgTopGradientUrl})`,
              bgRepeat: 'no-repeat',
              bgSize: `${moreThan1920 ? 'cover' : 'contain'}`,
              zIndex: -1,
              bgPosition: 'center top',
            }
          : {}
      }
      {...props}
    >
      {isMobile ? (
        <MobileNavbar menuItems={menuItems} />
      ) : (
        <Navbar menuItems={menuItems} />
      )}
      <Box flex={1}>{children}</Box>
      <Footer />
    </Flex>
  )
}

export default Page
