import { CopyIcon } from '@chakra-ui/icons'
import { Box, Stack, Tooltip, useToast } from '@chakra-ui/react'
import copy from 'copy-to-clipboard'
import { useTranslation } from 'react-i18next'

const Copiable = ({ children, copiableText, ...props }) => {
  const { t } = useTranslation()
  const toast = useToast()

  const handleCopy = () => {
    copy(copiableText)
    toast({
      title: t('common.Copied'),
      position: 'bottom',
      duration: 1000,
      isClosable: true,
      status: 'success',
    })
  }

  return (
    <Stack direction={'row'} alignItems={'center'} {...props}>
      <Box>{children}</Box>
      <Tooltip label={t('common.Copy to clipboard')}>
        <CopyIcon cursor={'pointer'} onClick={handleCopy} />
      </Tooltip>
    </Stack>
  )
}

export default Copiable
