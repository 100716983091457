import noPoolsFoundImage from '@/assets/no-pools-found.webp'
import { Image, Stack } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import Pool from './Pool'
import PoolsFilter from './PoolsFilter'

const Pools = ({
  pools = [],
  userAddress,
  refetchPool = null,
  movableState,
  setFilters,
}) => {
  const [tokens, setTokens] = useState([])
  const [isFirstTimeLoadedPools, setIsFirstTimeLoadedPools] = useState(false)

  useEffect(() => {
    if (pools.length > 0 && !isFirstTimeLoadedPools) {
      const allTokens = pools.map(({ token }) => token)
      const uniqueTokens = [...new Set(allTokens)]

      setTokens(uniqueTokens)
      setIsFirstTimeLoadedPools(true)
    }
  }, [pools, isFirstTimeLoadedPools])

  const handleFilterChange = (token) => {
    if (token) {
      setFilters({
        token,
      })
    } else {
      setFilters({})
    }
  }

  return (
    <Stack>
      {pools.length > 0 ? (
        <Stack gap={6} alignItems={'stretch'} justifyContent={'stretch'}>
          {!movableState && (
            <PoolsFilter tokens={tokens} onFilterChange={handleFilterChange} />
          )}
          <Stack
            flexWrap={'wrap'}
            justifyContent={'center'}
            alignItems={'stretch'}
            flexDirection={'row'}
            gap={{ base: '12px', md: '24px' }}
          >
            {pools.map(
              ({
                poolInfo,
                id,
                version,
                appVersion,
                address,
                userInfo,
                yieldSource,
                token,
                apr,
                endBlock,
              }) => (
                <Pool
                  key={`${id}:${address}`}
                  flex={1}
                  poolInfo={poolInfo}
                  endBlock={endBlock}
                  id={id}
                  address={address}
                  version={version}
                  appVersion={appVersion}
                  userAddress={userAddress}
                  userInfo={userInfo}
                  yieldSource={yieldSource}
                  token={token}
                  apr={apr}
                  movableState={movableState}
                  refetchPool={() => {
                    if (!refetchPool) return

                    refetchPool({
                      poolAddress: address,
                    })
                  }}
                />
              ),
            )}
          </Stack>
        </Stack>
      ) : (
        <Image
          alignSelf={'center'}
          mt={{ base: '10px', md: '30px', lg: '50px' }}
          w={{ base: 280, md: 350, lg: 466, xl: 666 }}
          src={noPoolsFoundImage}
          alt='No Pools Found'
        />
      )}
    </Stack>
  )
}

export default Pools
