import { ModalsContext } from '@/providers/ModalsProvider'
import { useContext } from 'react'

const useModals = () => {
  const { openModal, closeModal } = useContext(ModalsContext)

  return {
    openModal,
    closeModal,
  }
}

export default useModals
