import { InfoIcon } from '@chakra-ui/icons'
import { Text, Tooltip, VStack } from '@chakra-ui/react'

export default function Stats({ apr, players, t }) {
  return (
    <VStack
      alignItems={{ base: 'flex-start', sm: 'flex-end' }}
      fontWeight={500}
      color={'white'}
      fontSize={{ base: '16px', sm: '18px', md: '20px' }}
      gap={{ base: '4px', md: '8px' }}
    >
      <Text>
        ~{apr}% APR
        <Tooltip label={t('pools.Annual Percentage Rate')}>
          <InfoIcon verticalAlign={'center'} ml={1} fontSize={'14px'} />
        </Tooltip>
      </Text>

      {players && (
        <Text>
          {t('pools.Total Players')} : {players}
        </Text>
      )}
    </VStack>
  )
}
