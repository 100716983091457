import MODALS from '@/constants/modals'
import useModals from '@/hooks/useModals'
import { Button } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

const WalletConnectButton = ({ size, ...props }) => {
  const { openModal } = useModals()
  const { t } = useTranslation()

  const handleConnectWallet = () => {
    openModal(MODALS.WalletConnect)
  }

  return (
    <Button
      variant={'action'}
      size={size}
      onClick={handleConnectWallet}
      {...props}
    >
      {t('common.Connect Wallet')}
    </Button>
  )
}

export default WalletConnectButton
