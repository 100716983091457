import { WalletConnectButton, WrongNetworkButton } from '@/components/shared'
import { Button } from '@chakra-ui/react'
import {
  BoostAndCashoutAction,
  CashoutAction,
  ClaimPrizeAction,
  FinalizeAction,
  JoinPoolAction,
} from './PoolActions'

const PoolActionStates = (
  {
    isConnected,
    isCorrectChain,
    isStarted,
    isJoined,
    isPoolLastDay,
    onJoinPool,
    onBoost,
    onCashout,
    isCashouted,
    isFinished,
    isGameClosed,
    isWaitingFullfillment,
    isWinner,
    isClaimed,
    onClaim,
    onFinalize,
    isNewAppVersion,
    onMoveDeposit,
    movableState,
  },
  t,
) => ({
  notConnected: {
    condition: !isConnected,
    component: WalletConnectButton,
  },
  wrongNetwork: {
    condition: isConnected && !isCorrectChain,
    component: WrongNetworkButton,
  },
  started: {
    condition: isStarted,
    states: {
      notJoined: {
        condition: !isJoined,
        states: {
          poolLastDay: {
            condition: isPoolLastDay,
            component: () => (
              <Button whiteSpace={'unset'} isDisabled>
                {t('pools.You can’t join on the last day')}
              </Button>
            ),
          },
          allowJoin: {
            condition: !isPoolLastDay,
            component: () => (
              <JoinPoolAction
                onJoinPool={onJoinPool}
                movableState={movableState}
                onMoveDeposit={onMoveDeposit}
              />
            ),
          },
        },
      },
      joined: {
        condition: isJoined,
        states: {
          poolLastDay: {
            condition: !isNewAppVersion && isPoolLastDay,
            component: () => (
              <Button whiteSpace={'unset'} isDisabled>
                {t("pools.Can't boost or cashout on the last day")}
              </Button>
            ),
          },
          poolLastDayWithCashout: {
            condition: isNewAppVersion && isPoolLastDay,
            component: () => (
              <CashoutAction onCashout={onCashout} isCashouted={isCashouted} />
            ),
          },
          boostAndCashout: {
            condition: !isPoolLastDay,
            component: () => (
              <BoostAndCashoutAction
                movableState={movableState}
                onMoveDeposit={onMoveDeposit}
                onBoost={onBoost}
                onCashout={onCashout}
              />
            ),
          },
        },
      },
    },
  },
  gameClosed: {
    condition: isGameClosed,
    component: () => (
      <FinalizeAction
        isJoined={isJoined}
        onFinalize={onFinalize}
        variant='action'
      />
    ),
  },
  waitingFulfillment: {
    condition: isWaitingFullfillment,
    component: () => (
      <Button whiteSpace={'unset'} isDisabled variant='action'>
        {t('pools.Winners will be announced soon!')}
      </Button>
    ),
  },
  finished: {
    condition: isFinished,
    states: {
      notJoined: {
        condition: !isJoined,
        component: () => (
          <Button isDisabled>{t('pools.Pool is finished')}</Button>
        ),
      },
      joined: {
        condition: isJoined,
        states: {
          winner: {
            condition: isWinner,
            states: {
              claimed: {
                condition: isClaimed,
                component: () => (
                  <Button whiteSpace={'unset'} isDisabled variant={'action'}>
                    {t('pools.You have claimed your reward!')}
                  </Button>
                ),
              },
              notClaimed: {
                condition: !isClaimed,
                component: () => (
                  <ClaimPrizeAction
                    onClaim={onClaim}
                    onMoveDeposit={onMoveDeposit}
                    isClaimed={isClaimed}
                  />
                ),
              },
            },
          },
          looser: {
            condition: isWinner === false,
            states: {
              cashedOut: {
                condition: isCashouted,
                component: () => (
                  <Button isDisabled>{t('pools.Already cashed out')}</Button>
                ),
              },
              notCashedOut: {
                condition: !isCashouted,
                component: () => (
                  <CashoutAction
                    onCashout={onCashout}
                    isCashouted={isCashouted}
                    onMoveDeposit={onMoveDeposit}
                  />
                ),
              },
            },
          },
        },
      },
    },
  },
})

export default PoolActionStates
