import { Pool, PoolHistory, PoolLeaderboard } from '@/components/pool'
import { Page } from '@/components/shared'
import { APP_VERSIONS } from '@/constants/appVersions'
import POOL_STATUSES from '@/constants/poolStatus'
import { getPool, getTokenDecimals } from '@/core/pool/common'
import useHistory from '@/hooks/useHistory'
import useLeaderboard from '@/hooks/useLeaderboard'
import usePool from '@/hooks/usePool'
import { getPoolStatusData, isWaitingFulfilmentState } from '@/utils/poolUtils'
import { AbsoluteCenter, Flex, Spinner, VStack } from '@chakra-ui/react'
import { useQueryClient } from '@tanstack/react-query'
import { MainContainer } from '@vaultwin/ui'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useAccount, useClient } from 'wagmi'
import { useQuery } from 'wagmi/query'

const SinglePool = () => {
  const { poolAddress } = useParams()
  const { address } = useAccount()
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const client = useClient()

  const {
    data: poolData,
    refetch: refetchSinglePool,
    isLoading,
  } = usePool(poolAddress, address)

  const token = poolData?.token
  const pool = poolData?.poolInfo
  const poolId = poolData?.id
  const version = poolData?.version
  const yieldSource = poolData?.yieldSource
  const winningNumbers = poolData?.userInfo.winningNumbers
  const appVersion = poolData?.appVersion

  const { data: decimals } = useQuery({
    queryKey: ['decimals', token],
    queryFn: () => getTokenDecimals({ token }),
    enabled: !!token,
  })

  const { status } = getPoolStatusData({ status: pool?.status, end: pool?.end })

  const isWaitingFullfillment = isWaitingFulfilmentState({
    status,
    winningNumbers,
    isNewAppVersion: appVersion === APP_VERSIONS.V2,
    endBlock: poolData?.endBlock,
  })
  const showOnlyWinners =
    status === POOL_STATUSES.finished && !isWaitingFullfillment

  const {
    data: leaderboard = [],
    isLoading: isLoadingLeaderboard,
    refetch: refetchLeaderboard,
  } = useLeaderboard({
    poolAddress,
    poolId,
    version,
    onlyWinners: showOnlyWinners,
    limit: showOnlyWinners && poolData?.countOfWinners,
    appVersion,
    yieldSource,
    token,
  })

  const {
    data: history = [],
    isLoading: isLoadingHistory,
    refetch: refetchHistory,
  } = useHistory(client, {
    poolAddress,
    poolId,
    version,
    appVersion,
    userAddress: address,
    startBlock: poolData?.startBlock,
    endBlock: poolData?.endBlock,
    isJoined: poolData?.userInfo?.isJoined,
    yieldSource,
    decimals,
    token,
  })

  console.log('wining numbers: ', winningNumbers)

  useEffect(() => {
    if (poolData && poolAddress && version && appVersion) {
      queryClient.setQueryData(['pool', poolAddress, address], poolData)
    }
  }, [poolData, address, poolAddress, queryClient, version, appVersion])

  const refetchPool = async () => {
    refetchSinglePool()
    refetchLeaderboard()
    refetchHistory()

    const updatedPool = await getPool({
      poolAddress,
      userAddress: address,
    })

    queryClient.setQueryData(['live-pools'], (oldPools = []) =>
      oldPools.map((pool) =>
        pool.address === poolAddress ? updatedPool : pool,
      ),
    )

    queryClient.setQueryData(['finished-pools'], (oldPools = []) =>
      oldPools.map((pool) =>
        pool.address === poolAddress ? updatedPool : pool,
      ),
    )
  }

  return (
    <Page>
      <MainContainer pt={100}>
        {isLoading ? (
          <AbsoluteCenter>
            <Spinner size='xl' />
          </AbsoluteCenter>
        ) : (
          <VStack
            gap={{ base: '12px', md: '24px' }}
            alignItems={'stretch'}
            justifyContent={'stretch'}
          >
            {pool && (
              <Pool
                appVersion={appVersion}
                yieldSource={yieldSource}
                address={poolAddress}
                id={poolId}
                poolInfo={pool}
                endBlock={poolData?.endBlock}
                userAddress={address}
                userInfo={poolData?.userInfo}
                version={version}
                refetchPool={refetchPool}
                apr={poolData?.apr}
                isUserPool
                token={token}
                p={{
                  base: '16px',
                  sm: '20px 25px',
                  md: '20px 50px',
                  lg: '42px 130px',
                }}
                maxW={'full'}
              />
            )}

            <Flex
              gap={{ base: '12px', md: '24px' }}
              direction={{ base: 'column-reverse', lg: 'row' }}
              justifyContent={'center'}
            >
              <PoolHistory
                t={t}
                flex={1}
                history={history}
                isLoading={isLoadingHistory}
                countOfWinners={pool?.countOfWinners}
                token={poolData?.token}
              />

              <PoolLeaderboard
                t={t}
                flex={1}
                leaderboard={leaderboard}
                showOnlyWinners={showOnlyWinners}
                isLoading={isLoadingLeaderboard}
                countOfWinners={pool?.countOfWinners}
                token={poolData?.token}
              />
            </Flex>
          </VStack>
        )}
      </MainContainer>
    </Page>
  )
}

export default SinglePool
