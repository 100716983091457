import { config } from '@/configs/wagmi'
import CONTRACT_VERSIONS, {
  CONTRACT_ABI_BY_VERSIONS,
} from '@/constants/poolVersions'
import { userInfoSerializers } from '@/core/serializers/v1'
import timestampToMiliSeconds from '@/utils/timestampToMiliSeconds'
import { readContract } from '@wagmi/core'
import { format } from 'date-fns'
import { formatEther } from 'viem'
import { getBlock, getBlockNumber, getContractEvents } from 'viem/actions'

export const getUserInfo = async ({
  poolAddress,
  userAddress,
  poolId,
  version,
}) => {
  if (!userAddress) return {}

  const userId = await getUserId({ poolAddress, userAddress, poolId, version })

  if (!userId) return {}

  const [userInfo, chance, winningNumbers] = await Promise.all([
    readContract(config, {
      args: [poolId, userId],
      abi: CONTRACT_ABI_BY_VERSIONS[version],
      address: poolAddress,
      functionName: 'userInfo',
    }),

    getChance({ poolAddress, poolId, userAddress, version }),

    getUserWinningNumbers({ poolAddress, poolId, userAddress, version }),
  ])

  return {
    ...userInfoSerializers[version](userInfo),
    id: userId,
    chance,
    winningNumbers,
  }
}

const getUserWinningNumbers = async ({
  version,
  userAddress,
  poolAddress,
  poolId,
}) => {
  try {
    const winningNumbers = await readContract(config, {
      args:
        version === CONTRACT_VERSIONS.V1
          ? [BigInt(poolId)]
          : [userAddress, BigInt(poolId)],
      abi: CONTRACT_ABI_BY_VERSIONS[version],
      account: userAddress,
      address: poolAddress,
      functionName: 'check',
    })

    return winningNumbers
  } catch (err) {
    console.error(err.message)
    return []
  }
}

export const getUserId = async ({
  poolAddress,
  userAddress,
  poolId,
  version,
}) => {
  const userId = await readContract(config, {
    functionName: 'userID',
    args: [userAddress, poolId],
    abi: CONTRACT_ABI_BY_VERSIONS[version],
    address: poolAddress,
    query: { enabled: !!userAddress },
  })

  return userId
}

export const getCurrentUserIDInPool = async ({
  poolAddress,
  poolId,
  version,
}) => {
  const currentUserIDInPool = await readContract(config, {
    functionName: 'currentUserIDInPool',
    args: [poolId],
    abi: CONTRACT_ABI_BY_VERSIONS[version],
    address: poolAddress,
  })

  return currentUserIDInPool
}

export const getAddressOfUser = async ({
  poolAddress,
  poolId,
  version,
  userId,
}) => {
  try {
    if (version === CONTRACT_VERSIONS.V1) {
      throw new Error("V1 doesn't have IDToUser function.")
    }

    const userAddress = await readContract(config, {
      functionName: 'IDToUser',
      args: [poolId, userId],
      abi: CONTRACT_ABI_BY_VERSIONS[version],
      address: poolAddress,
    })

    return userAddress
  } catch (err) {
    console.error(err.message)
    return null
  }
}

export const getChance = async ({
  poolAddress,
  poolId,
  userAddress,
  version,
}) => {
  try {
    const userChance = await readContract(config, {
      functionName: 'getChance',
      args: [userAddress, poolId],
      abi: CONTRACT_ABI_BY_VERSIONS[version],
      address: poolAddress,
    })

    return userChance
  } catch (err) {
    console.error(err.message)
    return 0n
  }
}

export const getHistory = async (
  client,
  {
    poolAddress,
    poolId,
    version,
    userAddress,
    startBlock,
    endBlock,
    appVersion,
  },
) => {
  try {
    const numericStartBlock = Number(startBlock)
    const batchSize = 1000
    const currentEndBlock =
      endBlock === 'latest'
        ? Number(await getBlockNumber(client))
        : Number(endBlock)

    const allLogs = []
    const fetchPromises = []

    for (
      let toBlock = currentEndBlock;
      toBlock >= numericStartBlock;
      toBlock -= batchSize
    ) {
      const fromBlock = Math.max(toBlock - batchSize + 1, numericStartBlock)

      fetchPromises.push(
        fetchLogsPerBatch(client, {
          fromBlock,
          toBlock,
          poolAddress,
          poolId,
          userAddress,
          version,
          appVersion,
        }),
      )
    }

    const results = await Promise.all(fetchPromises)
    for (const result of results) {
      allLogs.push(...result)
    }

    return allLogs.sort((a, b) => Number(b.timestamp) - Number(a.timestamp))
  } catch (err) {
    console.error(err)
    return []
  }
}

const fetchLogsPerBatch = async (
  client,
  { poolId, version, poolAddress, userAddress, fromBlock, toBlock },
) => {
  const args = [CONTRACT_VERSIONS.V5].includes(version)
    ? { id: poolId, user: userAddress }
    : { user: userAddress }

  const rawLogs = await getContractEvents(client, {
    abi: CONTRACT_ABI_BY_VERSIONS[version],
    address: poolAddress,
    eventName: 'Stake',
    args,
    fromBlock: BigInt(fromBlock),
    toBlock: BigInt(toBlock),
  })

  return Promise.all(
    rawLogs.map(async (log) => {
      const { blockNumber, args } = log
      const {
        amount: deposit = 0n,
        totalTickets,
        userTickets,
        timestamp = 0n,
      } = args

      const chance = userTickets
        ? (formatEther(userTickets) / formatEther(totalTickets)) * 100
        : null

      let blockTimestamp = timestamp
      if (!timestamp) {
        const { timestamp: blockTime } = await getBlock(client, {
          blockNumber,
        })
        blockTimestamp = blockTime
      }

      const date = new Date(Number(timestampToMiliSeconds(blockTimestamp)))

      return {
        date: format(date, 'dd/MM/yy'),
        timestamp: blockTimestamp,
        deposit: formatEther(deposit),
        chance,
      }
    }),
  )
}
