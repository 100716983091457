import poolHistoryUrl from '@/assets/images/pool-history.webp'
import { getTokenSymbol } from '@/core/pool/common'
import { getUserWinProbability } from '@/utils/poolUtils'
import {
  Box,
  Flex,
  Heading,
  Image,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorMode,
} from '@chakra-ui/react'
import { useQuery } from 'wagmi/query'
import MockTableLoader from '../shared/MockTableLoader'

const VWTHead = ({ children, colorMode, ...props }) => {
  return (
    <Th
      fontWeight={500}
      fontSize={{ base: '14px', md: '16px' }}
      color={colorMode === 'light' ? 'primary.400' : 'white'}
      {...props}
    >
      {children}
    </Th>
  )
}

const PoolHistory = ({
  t,
  history,
  countOfWinners,
  isLoading,
  token,
  ...props
}) => {
  const { colorMode } = useColorMode()
  const { data: symbol } = useQuery({
    queryKey: ['symbol', token],
    queryFn: () => getTokenSymbol({ token }),
    enabled: !!token,
  })

  return (
    <Flex
      direction={'column'}
      p={{ base: '12px', md: '24px' }}
      bgColor={colorMode === 'light' ? 'white' : 'primary.700'}
      borderRadius={'24px'}
      border={'2px solid'}
      borderColor={'primary.500'}
      gap={{ base: 4, md: '36px' }}
      {...props}
    >
      <Flex justifyContent={'center'} alignItems={'center'} mb={0}>
        <Image
          src={poolHistoryUrl}
          alt={t('pools.Track Your Progress')}
          w={'45px'}
          h={'48px'}
          mr={'8px'}
        />

        <Heading fontWeight={600} textAlign={'center'} as='h1' fontSize='2xl'>
          {t('pools.Track Your Progress')}
        </Heading>
      </Flex>

      {isLoading ? (
        <MockTableLoader isLoaded={!isLoading} height={25} rows={4} />
      ) : (
        <Box>
          {history.length === 0 ? (
            <Text textAlign={'center'}>{t('pools.Not found any actions')}</Text>
          ) : (
            <TableContainer whiteSpace={'unset'} maxH={388} overflowY={'auto'}>
              <Table>
                <Thead
                  bg={colorMode === 'light' ? 'white' : '#f1ebff36'}
                  position='sticky'
                  top={0}
                  borderRadius={4}
                >
                  <Tr>
                    <VWTHead colorMode={colorMode}>{t('pools.Date')}</VWTHead>
                    <VWTHead colorMode={colorMode} isNumeric>
                      {t('pools.Deposit')}
                    </VWTHead>
                    <VWTHead colorMode={colorMode}>
                      {t('pools.Winning chance')}
                    </VWTHead>
                  </Tr>
                </Thead>
                <Tbody
                  fontWeight={300}
                  fontSize={{ base: '16px', md: '18px' }}
                  color={colorMode === 'light' ? 'gray.700' : 'white'}
                >
                  {history.map(({ date, timestamp, deposit, chance }) => (
                    <Tr key={timestamp}>
                      <Td>{date}</Td>
                      <Td isNumeric>
                        {Number(deposit)?.toFixed(2)} {symbol}
                      </Td>
                      <Td>
                        {chance
                          ? `${getUserWinProbability({ chance: chance / 100, countOfWinners })?.toFixed(2)}%`
                          : '-'}
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          )}
        </Box>
      )}
    </Flex>
  )
}

export default PoolHistory
