import { createIcon } from '@chakra-ui/react'

const MetaMaskCircleIcon = createIcon({
  displayName: 'MetaMaskCircleIcon',
  viewBox: '0 0 48 49',
  path: (
    <>
      <rect y='0.00390625' width='48' height='48' rx='24' fill='#233447' />
      <path
        d='M35.7058 11.0049L25.5605 18.5399L27.4367 14.0943L35.7058 11.0049Z'
        fill='#E2761B'
      />
      <path
        d='M12.2852 11.0039L22.3489 18.6103L20.5645 14.0934L12.2852 11.0039Z'
        fill='#E4761B'
      />
      <path
        d='M32.0555 28.4697L29.3535 32.6094L35.1348 34.2L36.7968 28.5615L32.0555 28.4697Z'
        fill='#E4761B'
      />
      <path
        d='M11.2148 28.5615L12.8666 34.2L18.6479 32.6094L15.9459 28.4697L11.2148 28.5615Z'
        fill='#E4761B'
      />
      <path
        d='M18.3219 21.4759L16.7109 23.9128L22.4514 24.1678L22.2475 17.999L18.3219 21.4759Z'
        fill='#E4761B'
      />
      <path
        d='M29.6696 21.476L25.6931 17.9277L25.5605 24.1678L31.2908 23.9129L29.6696 21.476Z'
        fill='#E4761B'
      />
      <path
        d='M18.6484 32.6097L22.0948 30.9273L19.1175 28.6025L18.6484 32.6097Z'
        fill='#E4761B'
      />
      <path
        d='M25.8965 30.9273L29.353 32.6097L28.8738 28.6025L25.8965 30.9273Z'
        fill='#E4761B'
      />
      <path
        d='M29.355 32.6101L25.8984 30.9277L26.1737 33.1811L26.1431 34.1294L29.355 32.6101Z'
        fill='#D7C1B3'
      />
      <path
        d='M18.6484 32.6101L21.8603 34.1294L21.8399 33.1811L22.0948 30.9277L18.6484 32.6101Z'
        fill='#D7C1B3'
      />
      <path
        d='M21.9105 27.115L19.0352 26.2687L21.0642 25.3408L21.9105 27.115Z'
        fill='#233447'
      />
      <path
        d='M26.0801 27.115L26.9264 25.3408L28.9656 26.2687L26.0801 27.115Z'
        fill='#233447'
      />
      <path
        d='M18.6473 32.6104L19.1367 28.4707L15.9453 28.5625L18.6473 32.6104Z'
        fill='#CD6116'
      />
      <path
        d='M28.8633 28.4707L29.3527 32.6104L32.0547 28.5625L28.8633 28.4707Z'
        fill='#CD6116'
      />
      <path
        d='M31.2908 23.9131L25.5605 24.168L26.0908 27.1147L26.937 25.3406L28.9763 26.2684L31.2908 23.9131Z'
        fill='#CD6116'
      />
      <path
        d='M19.0337 26.2684L21.073 25.3406L21.9091 27.1147L22.4495 24.168L16.709 23.9131L19.0337 26.2684Z'
        fill='#CD6116'
      />
      <path
        d='M16.7109 23.9141L19.1172 28.6043L19.0357 26.2694L16.7109 23.9141Z'
        fill='#E4751F'
      />
      <path
        d='M28.9789 26.2684L28.877 28.6034L31.2935 23.9131L28.9789 26.2684Z'
        fill='#E4751F'
      />
      <path
        d='M22.4525 24.168L21.9121 27.1147L22.5851 30.5916L22.738 26.0135L22.4525 24.168Z'
        fill='#E4751F'
      />
      <path
        d='M25.5624 24.168L25.2871 26.0033L25.4095 30.5916L26.0926 27.1147L25.5624 24.168Z'
        fill='#E4751F'
      />
      <path
        d='M26.0914 27.1139L25.4082 30.5908L25.8976 30.9273L28.8749 28.6025L28.9769 26.2676L26.0914 27.1139Z'
        fill='#F6851B'
      />
      <path
        d='M19.0352 26.2676L19.1167 28.6025L22.094 30.9273L22.5835 30.5908L21.9105 27.1139L19.0352 26.2676Z'
        fill='#F6851B'
      />
      <path
        d='M26.1427 34.1296L26.1733 33.1813L25.9184 32.957H22.0744L21.8399 33.1813L21.8603 34.1296L18.6484 32.6104L19.77 33.528L22.0438 35.1084H25.9489L28.2329 33.528L29.3545 32.6104L26.1427 34.1296Z'
        fill='#C0AD9E'
      />
      <path
        d='M25.8979 30.9283L25.4085 30.5918H22.5842L22.0947 30.9283L21.8398 33.1816L22.0744 32.9573H25.9183L26.1732 33.1816L25.8979 30.9283Z'
        fill='#161616'
      />
      <path
        d='M36.1335 19.0283L37.0002 14.8683L35.7053 11.0039L25.8965 18.284L29.6691 21.4754L35.0017 23.0355L36.1845 21.659L35.6747 21.2919L36.4904 20.5476L35.8582 20.0582L36.6739 19.4362L36.1335 19.0283Z'
        fill='#763D16'
      />
      <path
        d='M11 14.8683L11.8667 19.0283L11.3161 19.4362L12.1318 20.0582L11.5098 20.5476L12.3255 21.2919L11.8157 21.659L12.9883 23.0355L18.3209 21.4754L22.0935 18.284L12.2847 11.0039L11 14.8683Z'
        fill='#763D16'
      />
      <path
        d='M35.0029 23.0356L29.6703 21.4756L31.2915 23.9125L28.875 28.6028L32.0562 28.562H36.7975L35.0029 23.0356Z'
        fill='#F6851B'
      />
      <path
        d='M18.3216 21.4756L12.989 23.0356L11.2148 28.562H15.9459L19.1169 28.6028L16.7106 23.9125L18.3216 21.4756Z'
        fill='#F6851B'
      />
      <path
        d='M25.5606 24.1686L25.8971 18.2854L27.4469 14.0947H20.5645L22.0939 18.2854L22.4508 24.1686L22.5731 26.0243L22.5833 30.5922H25.4077L25.4281 26.0243L25.5606 24.1686Z'
        fill='#F6851B'
      />
    </>
  ),
})

export default MetaMaskCircleIcon
