import cupUrl from '@/assets/cup.svg'
import {
  Heading as ChakraHeading,
  Flex,
  HStack,
  Image,
  Text,
  VStack,
} from '@chakra-ui/react'
import { Trans } from 'react-i18next'
import { Link } from 'react-router-dom'
import { PoolCardInfoBadge } from '..'

const Heading = ({ children, ...props }) => {
  return (
    <ChakraHeading
      as={Flex}
      flexDirection={'row'}
      alignItems={'center'}
      fontWeight={600}
      lineHeight={{ base: '40px', sm: '50px', md: '60px' }}
      fontSize={{ base: '36px', sm: '46px', lg: '56px' }}
      {...props}
    >
      {children}
    </ChakraHeading>
  )
}

export default function WinOrLose({ isUserPool, isWinner, t, ...props }) {
  if (isWinner) {
    if (isUserPool) {
      return (
        // TODO: add exact reward
        <HStack
          color={'white'}
          alignSelf={{ base: 'center', md: 'flex-start' }}
          {...props}
        >
          <Heading flexDirection={'row'} gap={{ base: 1, sm: 2, md: 4 }}>
            <Image
              src={cupUrl}
              alt="Winner's cup"
              w={{ base: '90.5px', md: '151px' }}
              h={{ base: '97px', md: '164px' }}
            />

            <Text>{t('pools.You Won!')}</Text>
          </Heading>
        </HStack>
      )
    }
    return (
      <PoolCardInfoBadge
        bgColor={'success.500'}
        title={t('pools.You Won!')}
        fontSize={'26px'}
        maxW={200}
        {...props}
      />
    )
  }

  if (isUserPool) {
    return (
      <VStack
        maxW={450}
        color={'white'}
        gap={0}
        alignSelf={{ base: 'center', md: 'flex-start' }}
        {...props}
      >
        <Heading>{t('pools.You Lose!')}</Heading>

        <Text
          wordBreak={'break-word'}
          fontWeight={300}
          lineHeight={{ base: '28px', sm: '30px', md: '32px' }}
          fontSize={{ base: '20px', sm: '22px', md: '24px' }}
          textAlign={'center'}
        >
          <Trans i18nKey={"pools.Don't be discouraged"}>
            Don't be discouraged, Checkout
            <Text as={Link} textDecoration={'underline'} to={'/'}>
              Active Pools
            </Text>
          </Trans>
        </Text>
      </VStack>
    )
  }

  return (
    <PoolCardInfoBadge
      bgColor={'red'}
      title={t('pools.You Lose!')}
      fontSize={'26px'}
      maxW={200}
      {...props}
    />
  )
}
