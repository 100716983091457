import { Button, Flex, Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useChainId, useSwitchChain } from 'wagmi'

const WrongNetworkButton = ({ ...props }) => {
  const { t } = useTranslation()
  const chainId = useChainId()
  const { switchChain } = useSwitchChain()

  const switchToNetwork = () => {
    switchChain({ chainId })
  }

  return (
    <Flex
      direction={'column'}
      border={'1px solid rgba(255, 172, 47, 0.2)'}
      borderRadius={'20px'}
      p={'24px'}
      bgColor={'rgb(0 0 0 / 20%)'}
      zIndex={1}
      {...props}
    >
      <Text mb={'12px'} fontSize={'13px'} fontWeight={400} color={'orange'}>
        {t('common.You are on another network. Please switch to', {
          network: 'Bahamut',
        })}
      </Text>
      <Button variant={'action'} onClick={switchToNetwork}>
        {t('common.Switch to', { network: 'Bahamut' })}
      </Button>
    </Flex>
  )
}

export default WrongNetworkButton
