import { PoolCardActions } from '@/components/pool'
import { Timer } from '@/components/shared'
import { APP_VERSIONS } from '@/constants/appVersions'
import POOL_STATUSES from '@/constants/poolStatus'
import { VStack, useMediaQuery } from '@chakra-ui/react'

const PoolCardFooter = ({
  onBoost,
  onCashout,
  onJoinPool,
  onClaim,
  onFinalize,
  onMoveDeposit,
  movableState,

  isWinner,
  isClaimed,
  status,
  timerTimestamp,
  onTimerFinish,
  isJoined,
  isPoolLastDay,
  isCorrectChain,
  isConnected,
  isCashouted,
  isWaitingFullfillment,
  appVersion,
}) => {
  const [showDividers] = useMediaQuery('(min-width: 389px)', { ssr: false })

  return (
    <VStack gap={'16px'} alignItems={'stretch'}>
      {status !== POOL_STATUSES.finished && (
        <Timer
          showDividers={showDividers}
          timestamp={Number(timerTimestamp)}
          onTimerFinish={onTimerFinish}
          justifyContent={'space-evenly'}
          m={'auto'}
          w={'full'}
          maxW={500}
        />
      )}

      <PoolCardActions
        onBoost={onBoost}
        onCashout={onCashout}
        onJoinPool={onJoinPool}
        onClaim={onClaim}
        onFinalize={onFinalize}
        onMoveDeposit={onMoveDeposit}
        isJoined={isJoined}
        isGameClosed={status === POOL_STATUSES.gameClosed}
        isFinished={status === POOL_STATUSES.finished}
        isStarted={status === POOL_STATUSES.started}
        isPoolLastDay={isPoolLastDay}
        isCorrectChain={isCorrectChain}
        isConnected={isConnected}
        isCashouted={isCashouted}
        isWaitingFullfillment={isWaitingFullfillment}
        isWinner={isWinner}
        isClaimed={isClaimed}
        flexDir={{ base: 'column', md: 'row' }}
        isNewAppVersion={appVersion === APP_VERSIONS.V2}
        movableState={movableState}
      />
    </VStack>
  )
}

export default PoolCardFooter
