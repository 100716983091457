import { RegistryAbi } from '@/abis/v2/RegistryAbi'
import { bahamut } from '@/configs/chains'
import { config } from '@/configs/wagmi'
import { APP_VERSIONS } from '@/constants/appVersions'
import { COINS_ADDRESSES } from '@/constants/coins'
import POOL_STATUSES from '@/constants/poolStatus'
import {
  getPool as v1getPool,
  getTopPlayers as v1getTopPlayers,
  getWinners as v1getWinners,
} from '@/core/pool/v1/read'
import {
  claimAction as v1claimAction,
  finalizePoolAction as v1finalizePoolAction,
  stakeAction as v1stakeAction,
  unstakeAction as v1unstakeAction,
} from '@/core/pool/v1/write'
import {
  getPool as v2getPool,
  getTopPlayers as v2getTopPlayers,
  getWinners as v2getWinners,
} from '@/core/pool/v2/read'
import {
  claimAction as v2claimAction,
  finalizePoolAction as v2finalizePoolAction,
  stakeAction as v2stakeAction,
  unstakeAction as v2unstakeAction,
} from '@/core/pool/v2/write'
import { readContract } from '@wagmi/core'
import { erc20Abi, etherUnits } from 'viem'

export const ACTIONS = {
  [APP_VERSIONS.V1]: {
    getPool: v1getPool,
    getWinners: v1getWinners,
    getTopPlayers: v1getTopPlayers,
    stakeAction: v1stakeAction,
    unstakeAction: v1unstakeAction,
    finalizePoolAction: v1finalizePoolAction,
    claimAction: v1claimAction,
  },
  [APP_VERSIONS.V2]: {
    getPool: v2getPool,
    getWinners: v2getWinners,
    getTopPlayers: v2getTopPlayers,
    stakeAction: v2stakeAction,
    unstakeAction: v2unstakeAction,
    finalizePoolAction: v2finalizePoolAction,
    claimAction: v2claimAction,
  },
}

export const stakeAction = async ({
  poolAddress,
  yieldSource,
  value,
  toast,
  appVersion,
  version,
  token,
}) => {
  try {
    return ACTIONS[appVersion].stakeAction({
      poolAddress,
      value,
      version,
      appVersion,
      yieldSource,
      toast,
      token,
    })
  } catch (error) {
    console.error('Failed to stake:', error)

    return null
  }
}

export const unstakeAction = async ({
  poolAddress,
  yieldSource,
  value,
  toast,
  appVersion,
  version,
  token,
}) => {
  try {
    return ACTIONS[appVersion].unstakeAction({
      poolAddress,
      value,
      version,
      yieldSource,
      toast,
      token,
    })
  } catch (error) {
    console.error('Failed to unstake:', error)

    return null
  }
}

export const getFinishedPools = async ({ userAddress }) => {
  try {
    const pools = await getPools(userAddress)

    return pools
      .filter(
        (pool) =>
          pool.poolInfo?.isFinalized &&
          pool.poolInfo?.status === POOL_STATUSES.finished,
      )
      .sort((a, b) => Number(b.poolInfo.start) - Number(a.poolInfo.start))
  } catch (e) {
    console.error('Error getting finished pools', e.message)

    return []
  }
}

const getLotteries = async () => {
  try {
    const lotteries = await readContract(config, {
      abi: RegistryAbi,
      address: bahamut.contracts.registryProxy.address,
      functionName: 'getLotteries',
    })

    return lotteries
  } catch (e) {
    console.error('Error getting lotteries: ', e.message)
    return []
  }
}

const getPoolStateInfo = async ({ lotteryAddress }) => {
  try {
    const [isOld, _version] = await readContract(config, {
      abi: RegistryAbi,
      address: bahamut.contracts.registryProxy.address,
      functionName: 'poolStateInfo',
      args: [lotteryAddress],
    })

    return {
      version: Number(_version),
      isOld,
    }
  } catch (err) {
    console.error('Error getting old pool version: ', err.message)
    return {}
  }
}

const getPoolMetadata = async (lotteryAddress) => {
  try {
    const metadata = await readContract(config, {
      abi: RegistryAbi,
      address: bahamut.contracts.registryProxy.address,
      functionName: 'poolInfo',
      args: [lotteryAddress],
    })

    const [token, lendToken, _roundStart, _roundEnd, version, yieldSource] =
      metadata

    const { version: oldVersion, isOld } = await getPoolStateInfo({
      lotteryAddress,
    })

    const finalVersion = isOld ? oldVersion : Number(version)

    return {
      token,
      lendToken,
      version: Number(finalVersion),
      yieldSource,
      address: lotteryAddress,
      appVersion: isOld ? APP_VERSIONS.V1 : APP_VERSIONS.V2,
    }
  } catch (e) {
    console.error('Error getting pool metadata: ', e)
    return {}
  }
}

export const getPools = async (userAddress) => {
  try {
    const lotteries = await getLotteries()
    const poolsPromises = []

    for (const lottery of lotteries) {
      poolsPromises.push(
        getPool({
          userAddress,
          poolAddress: lottery,
        }),
      )
    }

    const pools = await Promise.all(poolsPromises)

    return pools.filter((pool) => pool)
  } catch (err) {
    console.error('Error getting pools: ', err.message)
    return []
  }
}

export const getLivePools = async ({ userAddress }) => {
  try {
    const pools = await getPools(userAddress)

    return pools.sort(
      (a, b) => Number(b.poolInfo.start) - Number(a.poolInfo.start),
    )
  } catch (e) {
    console.error(e)

    return []
  }
}

export const getPool = async ({ poolAddress, userAddress }) => {
  const metadata = await getPoolMetadata(poolAddress)

  return ACTIONS[metadata.appVersion].getPool({
    poolAddress,
    userAddress,
    metadata,
  })
}

export const getLeaderboard = async ({
  poolAddress,
  poolId,
  version,
  onlyWinners,
  appVersion,
  yieldSource,
  token,
}) => {
  try {
    if (onlyWinners) {
      return ACTIONS[appVersion].getWinners({
        poolAddress,
        poolId,
        version,
        yieldSource,
        token,
      })
    }

    return ACTIONS[appVersion].getTopPlayers({
      poolAddress,
      poolId,
      version,
      yieldSource,
      token,
    })
  } catch (error) {
    console.error('Failed to get leaderboard:', error.message)
    return []
  }
}

export const getTokenSymbol = async ({ token }) => {
  try {
    if (token === COINS_ADDRESSES.FTN) {
      return 'FTN'
    }

    const symbol = await readContract(config, {
      address: token,
      functionName: 'symbol',
      abi: erc20Abi,
    })

    return symbol
  } catch (e) {
    console.error(e)
    return ''
  }
}

export const getTokenDecimals = async ({ token }) => {
  try {
    if (token === COINS_ADDRESSES.FTN) {
      return etherUnits.wei
    }

    const decimals = await readContract(config, {
      address: token,
      functionName: 'decimals',
      abi: erc20Abi,
    })

    return decimals
  } catch (e) {
    console.error(e)
    return etherUnits.wei
  }
}

export const getBalanceOfPool = async ({ tokenAddress, poolAddress }) => {
  try {
    const balance = await readContract(config, {
      abi: erc20Abi,
      address: tokenAddress,
      functionName: 'balanceOf',
      args: [poolAddress],
    })

    return balance
  } catch (err) {
    console.error('Failed to get balance of pool:', err.message)
    return 0n
  }
}

export const finalizePoolAction = async ({
  poolAddress,
  yieldSource,
  toast,
  appVersion,
  version,
  token,
}) => {
  try {
    return ACTIONS[appVersion].finalizePoolAction({
      poolAddress,
      version,
      yieldSource,
      toast,
      token,
    })
  } catch (error) {
    console.error('Failed to stake:', error)

    return null
  }
}

export const claimAction = async ({
  poolAddress,
  yieldSource,
  toast,
  appVersion,
  version,
  token,
}) => {
  try {
    return ACTIONS[appVersion].claimAction({
      poolAddress,
      version,
      yieldSource,
      toast,
      token,
    })
  } catch (error) {
    console.error('Failed to claim:', error)

    return null
  }
}
