import VwLogoUrl from '@/assets/vw-logo.svg'
import { MoonIcon, SunIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Flex,
  HStack,
  Image,
  Link,
  Stack,
  useColorMode,
  useColorModeValue,
} from '@chakra-ui/react'
import {
  GetNotifiedButton,
  LanguageDropdown,
  MainContainer,
} from '@vaultwin/ui'
import { APPS, getBaseUrl } from '@vaultwin/utils'
import { useTranslation } from 'react-i18next'
import { Link as routerLink, useLocation } from 'react-router-dom'
import { useAccount } from 'wagmi'
import Account from './Account'

const currentApp = APPS.APP

export default function Navbar({ menuItems = [], ...props }) {
  const { colorMode, toggleColorMode } = useColorMode()
  const { address } = useAccount()
  const { pathname } = useLocation()
  const { t } = useTranslation()

  return (
    <Box
      position={'sticky'}
      filter='auto'
      left={0}
      top={0}
      py={'20px'}
      zIndex={10}
      _before={{
        content: `""`,
        display: 'block',
        position: 'absolute',
        pointerEvents: 'none',
        left: 0,
        top: 0,
        zIndex: 0,
        width: '100%',
        height: '100%',
        backgroundColor:
          colorMode === 'light' ? 'rgb(241 235 255 / 30%)' : 'rgb(0 0 0 / 30%)',
        backdropFilter: 'blur(6px)',
      }}
      {...props}
    >
      <MainContainer>
        <Flex
          position={'relative'}
          alignItems={'center'}
          justifyContent={'space-between'}
          gap={3}
        >
          <HStack zIndex={2} gap={{ base: 2, md: 4, lg: 6, xl: '40px' }}>
            <Box as={routerLink} to={'/'} cursor={'pointer'}>
              <Image w={{ base: 100, lg: 150 }} src={VwLogoUrl} alt='VW Logo' />
            </Box>

            <Stack direction={'row'} spacing={3}>
              {menuItems.map((item) =>
                item.secure && !address ? null : (
                  <Link
                    textAlign={{ base: 'center' }}
                    as={routerLink}
                    fontSize={'14px'}
                    _hover={{
                      textDecoration: 'none',
                    }}
                    bgColor={
                      pathname === item.path
                        ? useColorModeValue('whiteAlpha.400', 'transparent')
                        : 'transparent'
                    }
                    px={2}
                    py={1}
                    borderRadius={'md'}
                    color={
                      pathname === item.path
                        ? useColorModeValue('primary.500', 'primary.400')
                        : useColorModeValue('gray.800', 'gray.100')
                    }
                    fontWeight={400}
                    key={item.label}
                    to={
                      item.app && item.app !== currentApp
                        ? getBaseUrl(item.app, item.path)
                        : item.path
                    }
                    target={
                      item.app && item.app !== currentApp ? '_blank' : '_self'
                    }
                  >
                    {t(`menu.${item.label}`)}
                  </Link>
                ),
              )}
            </Stack>
          </HStack>

          <Flex alignItems={'center'} direction={'row'} gap={3}>
            <GetNotifiedButton />
            <Button
              width={{ base: '24px', md: '48px' }}
              onClick={toggleColorMode}
              _light={{
                bgColor: 'whiteAlpha.300',
                _hover: { bgColor: 'whiteAlpha.400' },
              }}
            >
              {colorMode === 'light' ? <MoonIcon /> : <SunIcon />}
            </Button>

            <LanguageDropdown />

            <Account />
          </Flex>
        </Flex>
      </MainContainer>
    </Box>
  )
}
