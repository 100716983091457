import { Text, VStack } from '@chakra-ui/react'

export default function Dates({ duration, startDate, endDate, isStarted, t }) {
  return (
    <VStack
      alignItems={{ base: 'flex-start' }}
      gap={{ base: '4px', sm: '8px', md: '16px' }}
      fontSize={{ base: '15px', md: '16px' }}
      color={'white'}
      fontWeight={400}
    >
      <Text>
        {t('pools.Start Date')}: {startDate}
      </Text>
      <Text>
        {isStarted ? t('pools.Duration') : t('pools.End Date')}:{' '}
        {isStarted ? duration : endDate}
      </Text>
    </VStack>
  )
}
