import {
  Button,
  Flex,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
  VStack,
  useColorMode,
  useDisclosure,
  useOutsideClick,
} from '@chakra-ui/react'
import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useAccount, useDisconnect } from 'wagmi'
import { AddressBadge, WalletConnectButton } from './shared'

const Account = ({ linkStyles, ...props }) => {
  const { address, connector } = useAccount()
  const { disconnect } = useDisconnect()
  const { colorMode } = useColorMode()
  const { t } = useTranslation()
  const { isOpen, onToggle, onClose } = useDisclosure()
  const popoverRef = useRef()

  useOutsideClick({
    ref: popoverRef,
    handler: onClose,
  })

  return (
    <Flex alignItems={'center'} ref={popoverRef}>
      {address ? (
        <Popover isOpen={isOpen} onClose={onClose} {...props}>
          <PopoverTrigger>
            <AddressBadge
              onClick={onToggle}
              as={Button}
              linkStyles={linkStyles}
              cursor={'pointer'}
              py={1}
              withCopy={false}
              address={address}
              _light={{
                bgColor: 'whiteAlpha.300',
                _hover: { bgColor: 'whiteAlpha.500' },
              }}
            />
          </PopoverTrigger>

          <PopoverContent>
            <PopoverBody alignItems={'flex-start'} as={VStack} flex={1}>
              <Text fontSize={'small'}>
                {t('common.Connected via', { connector: connector?.name })}
              </Text>

              <AddressBadge
                py={1}
                withCopy={true}
                symbols={10}
                address={address}
              />

              <Button
                alignSelf={'flex-end'}
                justifySelf={'flex-end'}
                variant={colorMode === 'light' ? 'outline' : 'outlineWhite'}
                onClick={() => {
                  disconnect()
                }}
              >
                {t('common.Disconnect')}
              </Button>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      ) : (
        <WalletConnectButton />
      )}
    </Flex>
  )
}

export default Account
