import { HStack, Text, Tooltip, VStack } from '@chakra-ui/react'
import React from 'react'
import Coin from './Coin'

const PrizeTitle = ({ children }) => (
  <Text
    textTransform={'uppercase'}
    fontWeight={400}
    fontSize={'16px'}
    letterSpacing={'0.8px'}
    wordBreak={'break-word'}
  >
    {children}
  </Text>
)

const PrizeText = React.forwardRef(({ children, ...props }, ref) => (
  <Text
    ref={ref}
    lineHeight={{ base: '37px', md: '45px' }}
    fontWeight={900}
    fontSize={{ base: '28px', md: '36px' }}
    wordBreak={'break-word'}
    {...props}
  >
    {children}
  </Text>
))

export default function Prizes({
  accumulatedPrize,
  finalPrize,
  winners,
  isFinished,
  isGameClosed,
  isUserPool,
  t,
  token,
  ...props
}) {
  return (
    <VStack gap={{ base: '8px', md: '16px' }} {...props}>
      <Text
        fontSize={{ base: '15px', md: '16px' }}
        color={'primary.200'}
        fontWeight={400}
        letterSpacing={'0.8px'}
        lineHeight={{ base: '19px', md: '20px' }}
        textDecoration={'underline'}
        textTransform={'uppercase'}
      >
        {t('pools.Prize')} {t('common.For')} {winners}{' '}
        {winners > 1 ? t('pools.Winners') : t('pools.Winner')}
      </Text>

      <HStack
        gap={{ base: 2, md: 6 }}
        wrap={'wrap'}
        flexDirection={{
          base: 'column',
          sm: 'row',
        }}
        color={'white'}
        alignItems={'flex-start'}
      >
        {(accumulatedPrize || (!accumulatedPrize && isFinished)) && (
          <VStack alignItems={'flex-start'}>
            <PrizeTitle>{t('pools.Accumulated')}</PrizeTitle>

            <HStack>
              <Tooltip label={accumulatedPrize}>
                <PrizeText>
                  {accumulatedPrize &&
                    Number(accumulatedPrize || 0)?.toFixed(2)}
                </PrizeText>
              </Tooltip>
              <Coin token={token} />
            </HStack>
          </VStack>
        )}

        {!isFinished && !isGameClosed && finalPrize && finalPrize > 0 && (
          <VStack alignItems={'flex-start'}>
            <PrizeTitle>{t('pools.Estimated Final')}</PrizeTitle>

            <HStack>
              <Tooltip label={finalPrize}>
                <PrizeText>{Number(finalPrize || 0)?.toFixed(2)}</PrizeText>
              </Tooltip>
              <Coin token={token} />
            </HStack>
          </VStack>
        )}
      </HStack>
    </VStack>
  )
}
