import { Button, Stack } from '@chakra-ui/react'
import React from 'react'
import Jazzicon, { jsNumberForAddress } from 'react-jazzicon'

const IdentIcon = React.forwardRef(
  ({ address, diameter = 24, ...props }, ref) => {
    return (
      <Stack ref={ref} p={1} as={Button} {...props}>
        <Jazzicon diameter={diameter} seed={jsNumberForAddress(address)} />
      </Stack>
    )
  },
)

export default IdentIcon
