import FinishedPools from '@/pages/FinishedPools'
import LivePools from '@/pages/LivePools'
import MyPools from '@/pages/MyPools'
import SinglePool from '@/pages/SinglePool'
import Test from '@/pages/Test'
import {
  RouterProvider as NativeRouterProvider,
  createBrowserRouter,
} from 'react-router-dom'

const router = createBrowserRouter([
  {
    path: '/',
    element: <LivePools />,
  },
  {
    path: '/finished-pools',
    element: <FinishedPools />,
  },
  {
    path: '/my-pools',
    element: <MyPools />,
  },
  {
    path: '/pool/:poolAddress',
    element: <SinglePool />,
  },
  {
    path: '/test',
    element: <Test />,
  },
])

const RouterProvider = () => <NativeRouterProvider router={router} />

export default RouterProvider
