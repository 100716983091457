import { Box, HStack } from '@chakra-ui/react'
import React from 'react'
import Address from './Address'
import AddressWithCopy from './AddressWithCopy'
import IdentIcon from './IdentIcon'

const AddressBadge = React.forwardRef(
  (
    {
      address,
      symbols = 4,
      fullAddress = false,
      withCopy = false,
      linkStyles,
      ...props
    },
    ref,
  ) => {
    return address ? (
      <Box overflow={'hidden'} {...props} ref={ref}>
        <HStack>
          <IdentIcon as={Box} address={address} />

          {withCopy ? (
            <AddressWithCopy
              fullAddress={fullAddress}
              symbols={symbols}
              address={address}
              linkStyles={linkStyles}
            />
          ) : (
            <Address
              fullAddress={fullAddress}
              symbols={symbols}
              address={address}
              linkStyles={linkStyles}
            />
          )}
        </HStack>
      </Box>
    ) : null
  },
)

export default AddressBadge
