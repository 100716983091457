import POOL_STATUSES from '@/constants/poolStatus'
import CONTRACT_VERSIONS from '@/constants/v2/poolVersions'
import { getPoolStatusData } from '@/utils/poolUtils'
import timestampToMiliSeconds from '@/utils/timestampToMiliSeconds'

const v1PoolSerializer = (poolInfo = []) => {
  const [
    _poolStart,
    _countOfWinners,
    _totalCurrentDeposit,
    _totalDepositAtTheEnd,
    _totalTickets,
    _duration,
    _rewardForRound,
    _isFinalized,
  ] = poolInfo

  const duration = _duration ? timestampToMiliSeconds(_duration) : 0n
  const start = _poolStart ? timestampToMiliSeconds(_poolStart) : 0n
  const end = _poolStart ? timestampToMiliSeconds(_poolStart) + duration : 0n

  const isStarted = +new Date() >= start
  const status = _isFinalized
    ? POOL_STATUSES.finished
    : isStarted
      ? POOL_STATUSES.started
      : POOL_STATUSES.notStarted
  const statusData = getPoolStatusData({ status, end })

  return {
    start,
    end,
    countOfWinners: Number(_countOfWinners),
    totalDeposit: _totalDepositAtTheEnd || _totalCurrentDeposit,
    totalTickets: _totalTickets,
    duration,
    rewardForRound: _rewardForRound,
    isFinalized: _isFinalized,
    status: statusData.status,
  }
}

export const poolSerializers = {
  [CONTRACT_VERSIONS.V1]: v1PoolSerializer,
}

const v1UserInfoSerializer = (userInfo = []) => {
  const [depositedAssets, depositedAssetsAtTheEnd, tickets, rewardsClaimed] =
    userInfo
  return {
    isJoined: depositedAssets > 0n || tickets > 0n,
    depositInPool: depositedAssets,
    finalDeposit: depositedAssetsAtTheEnd,
    tickets,
    // lastUpdatedTime,
    isClaimed: rewardsClaimed,
    isCashouted: depositedAssets === 0n,
  }
}

export const userInfoSerializers = {
  [CONTRACT_VERSIONS.V1]: v1UserInfoSerializer,
}
