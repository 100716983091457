import POOL_STATUSES, { POOL_STATUSES_CONTRACT } from '@/constants/poolStatus'
import CONTRACT_VERSIONS from '@/constants/poolVersions'
import timestampToMiliSeconds from '@/utils/timestampToMiliSeconds'

const v3PoolSerializer = (poolInfo = []) => {
  const [
    _poolStart,
    _countOfWinners,
    _totalCurrentDeposit,
    _totalDepositForPool,
    _totalDepositAtTheEnd,
    _totalShare,
    _totalTickets,
    _duration,
    _rewardForRound,
    _poolStatus,
  ] = poolInfo

  const duration = _duration ? timestampToMiliSeconds(_duration) : 0n

  return {
    start: _poolStart ? timestampToMiliSeconds(_poolStart) : 0n,
    end: _poolStart ? timestampToMiliSeconds(_poolStart) + duration : 0n,
    countOfWinners: Number(_countOfWinners),
    totalDeposit: _totalCurrentDeposit,
    totalTickets: _totalTickets,
    duration,
    rewardForRound: _rewardForRound,
    status: POOL_STATUSES_CONTRACT[_poolStatus],
    isFinalized: POOL_STATUSES_CONTRACT[_poolStatus] === POOL_STATUSES.finished,
  }
}

const v4PoolSerializer = (poolInfo = []) => {
  const [
    _poolStart,
    _countOfWinners,
    _totalCurrentDeposit,
    _totalDepositForPool,
    _totalDepositAtTheEnd,
    _totalTickets,
    _duration,
    _rewardForRound,
    _poolStatus,
  ] = poolInfo

  const duration = _duration ? timestampToMiliSeconds(_duration) : 0n

  return {
    start: _poolStart ? timestampToMiliSeconds(_poolStart) : 0n,
    end: _poolStart ? timestampToMiliSeconds(_poolStart) + duration : 0n,
    countOfWinners: Number(_countOfWinners),
    totalDeposit: _totalCurrentDeposit,
    totalTickets: _totalTickets,
    duration,
    rewardForRound: _rewardForRound,
    status: POOL_STATUSES_CONTRACT[_poolStatus],
    isFinalized: POOL_STATUSES_CONTRACT[_poolStatus] === POOL_STATUSES.finished,
  }
}

export const poolSerializers = {
  [CONTRACT_VERSIONS.V3]: v3PoolSerializer,
  [CONTRACT_VERSIONS.V6]: v4PoolSerializer,
  [CONTRACT_VERSIONS.V7]: v4PoolSerializer,
}

const v3UserInfoSerializer = (userInfo = []) => {
  const [depositFTN, depositFTNAtTheEnd, _share, tickets, isClaimed] = userInfo

  return {
    depositInPool: depositFTN,
    tickets,
    finalDeposit: depositFTNAtTheEnd,
    isJoined: depositFTN > 0n || tickets > 0n || depositFTNAtTheEnd > 0n,
    lastUpdatedTime: 0n,
    isClaimed,
    isCashouted: depositFTN === 0n,
  }
}

const v4UserInfoSerializer = (userInfo = []) => {
  const [depositFTN, depositFTNAtTheEnd, tickets, isClaimed] = userInfo

  return {
    depositInPool: depositFTN,
    tickets,
    finalDeposit: depositFTNAtTheEnd,
    isJoined: depositFTN > 0n || tickets > 0n || depositFTNAtTheEnd > 0n,
    lastUpdatedTime: 0n,
    isClaimed,
    isCashouted: depositFTN === 0n,
  }
}

export const userInfoSerializers = {
  [CONTRACT_VERSIONS.V3]: v3UserInfoSerializer,
  [CONTRACT_VERSIONS.V6]: v4UserInfoSerializer,
  [CONTRACT_VERSIONS.V7]: v4UserInfoSerializer,
}
