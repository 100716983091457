import { LotteryAbi_v3 } from '@/abis/LotteryAbi_v3'
import { config } from '@/configs/wagmi'
import { Box, Button, Heading } from '@chakra-ui/react'
import { writeContract } from '@wagmi/core'

import { useAccount, useChainId, useConnect, useDisconnect } from 'wagmi'

const Test = () => {
  const account = useAccount()
  const { connectors, connect, status, error } = useConnect()
  const chainId = useChainId()
  const { disconnect } = useDisconnect()

  const finalize = () => {
    writeContract(config, {
      abi: LotteryAbi_v3,
      address: '0x63c0d7485C4b47142Ef7b1590fE548773eeDB874',
      functionName: 'finalizeRound',
      args: [],
    })
  }

  return (
    <>
      <div>
        <Heading>Account</Heading>

        <Box>
          status: {account.status}
          <br />
          addresses: {JSON.stringify(account.addresses)}
          <br />
          chainId: {account.chainId}
          <br />
          App chainId: {chainId}
          <br />
          isCorrectChain: {account.chainId === chainId ? 'true' : 'false'}
        </Box>

        {account.status === 'connected' && (
          <Button onClick={() => disconnect()}>Disconnect</Button>
        )}
      </div>

      <div>
        <Button onClick={finalize}>Finalize</Button>
        <h2>Connect</h2>
        {connectors.map((connector) => (
          <Button key={connector.uid} onClick={() => connect({ connector })}>
            {connector.name}
          </Button>
        ))}
        <div>{status}</div>
        <div>{error?.message}</div>
      </div>
    </>
  )
}

export default Test
