import { supportedChains } from '@/configs/chains'
import { Link as ChakraLink, Tooltip } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useChainId } from 'wagmi'
import Address from './Address'
import Copiable from './Copiable'

const AddressWithCopy = ({
  address,
  symbols = 3,
  fullAddress = false,
  linkStyles = {},
  to,
}) => {
  const chainId = useChainId()
  const blockExplorerUrl =
    supportedChains[chainId]?.blockExplorers?.default?.url
  const url = `${blockExplorerUrl}/address/${address}`
  const { t } = useTranslation()

  return (
    <Copiable copiableText={address}>
      <ChakraLink
        variant={'solid'}
        target={'_blank'}
        referrerPolicy={'no-referrer'}
        href={to || url}
        _hover={{
          textDecoration: 'underline',
        }}
        {...linkStyles}
      >
        <Tooltip label={t('common.View in explorer')} placement={'top'}>
          <Address
            address={address}
            symbols={symbols}
            fullAddress={fullAddress}
          />
        </Tooltip>
      </ChakraLink>
    </Copiable>
  )
}

export default AddressWithCopy
