import { COIN_SYMBOLS } from '@/constants/coins'
import { Stack, Tab, TabIndicator, TabList, Tabs } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

const TabButton = ({ children, ...props }) => {
  return (
    <Tab
      fontWeight={600}
      fontSize={14}
      color={'gray.300'}
      _selected={{
        color: 'primary.500',
      }}
      {...props}
    >
      {children}
    </Tab>
  )
}

export default function PoolsFilter({ tokens = [], onFilterChange }) {
  const { t } = useTranslation()

  return (
    <Stack
      direction={{ base: 'column', md: 'row' }}
      alignItems={{ base: 'center', md: 'flex-start' }}
      justifyContent={{ base: 'center', md: 'flex-start' }}
      gap={{ base: '12px', md: '24px' }}
    >
      <Tabs variant='unstyled'>
        <TabList>
          <TabButton onClick={() => onFilterChange(null)}>
            {t('common.All')}
          </TabButton>

          {tokens.map((token) => (
            <TabButton key={token} onClick={() => onFilterChange(token)}>
              {COIN_SYMBOLS[token]}
            </TabButton>
          ))}
        </TabList>
        <TabIndicator mt='-1.5px' height='1px' bg='primary.500' />
      </Tabs>
    </Stack>
  )
}
