import { useTranslation } from 'react-i18next'
import PoolActionStates from './PoolActionStates'
import findActivePoolComponent from './helpers/findActivePoolComponent'

const getPoolActionComponent = (props) => {
  const { t } = useTranslation()
  return findActivePoolComponent(PoolActionStates(props, t))
}

export default getPoolActionComponent
