import { AbsoluteCenter, Spinner } from '@chakra-ui/react'

const AbsoluteSpinner = ({ ...props }) => {
  return (
    <AbsoluteCenter {...props}>
      <Spinner size={'xl'} />
    </AbsoluteCenter>
  )
}

export default AbsoluteSpinner
