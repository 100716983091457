import Pools from '@/components/pool/Pools'
import { AbsoluteSpinner, Page, TabStyled, Title } from '@/components/shared'
import { getPool } from '@/core/pool/common'
import useMyPools from '@/hooks/useMyPools'
import {
  Flex,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from '@chakra-ui/react'
import { useQueryClient } from '@tanstack/react-query'
import { MainContainer } from '@vaultwin/ui'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAccount } from 'wagmi'

const MyPools = () => {
  const { t } = useTranslation()
  const { address: userAddress } = useAccount()
  const queryClient = useQueryClient()
  const [filters, setFilters] = useState({})
  const {
    data: { activePools = [], historyPools = [] },
    isLoadingActivePools,
    isLoadingHistoryPools,
    refetchActivePools,
    refetchHistoryPools,
  } = useMyPools(userAddress, filters)

  const refetchPool = async ({ poolAddress }) => {
    const updatedPool = await getPool({
      poolAddress,
      userAddress,
    })

    queryClient.setQueryData(['live-pools'], (oldPools) =>
      oldPools.map((pool) =>
        pool.address === poolAddress ? updatedPool : pool,
      ),
    )

    queryClient.setQueryData(['finished-pools'], (oldPools) =>
      oldPools.map((pool) =>
        pool.address === poolAddress ? updatedPool : pool,
      ),
    )

    queryClient.setQueryData(['pool', poolAddress, userAddress], updatedPool)
  }

  useEffect(() => {
    if (!userAddress || !refetchActivePools || !refetchHistoryPools) return

    refetchActivePools()
    refetchHistoryPools()
  }, [userAddress, refetchActivePools, refetchHistoryPools])

  return (
    <Page position={'relative'}>
      <MainContainer>
        <Flex
          alignItems={'center'}
          justifyContent={'center'}
          gap={'16px'}
          direction={'column'}
          mb={'40px'}
        >
          <Flex mt={4} alignItems={'center'} justifyContent={'center'}>
            <Title
              lineHeight={'normal'}
              mr={'4px'}
              textAlign={'center'}
              fontWeight={600}
            >
              {t('pools.My Pools')}
            </Title>
          </Flex>

          <Text
            fontWeight={300}
            maxW={470}
            textAlign={'center'}
            fontSize={{ base: '16px', md: '18px' }}
          >
            {t('pools.This section displays all the pools')}
          </Text>
        </Flex>

        {userAddress ? (
          <Tabs
            position={'unset'}
            defaultIndex={0}
            isLazy
            variant='soft-rounded'
            colorScheme='primary'
            p={0}
            onChange={() => setFilters({})}
          >
            <MainContainer>
              <TabList px={0} mb={4} justifyContent={'center'}>
                <TabStyled>{t('pools.Active Pools')}</TabStyled>
                <TabStyled>{t('pools.History')}</TabStyled>
              </TabList>
            </MainContainer>
            <TabPanels>
              <TabPanel p={0}>
                {isLoadingActivePools ? (
                  <AbsoluteSpinner />
                ) : (
                  <Pools
                    pools={activePools}
                    userAddress={userAddress}
                    refetchPool={refetchPool}
                    setFilters={setFilters}
                  />
                )}
              </TabPanel>
              <TabPanel p={0}>
                {isLoadingHistoryPools ? (
                  <AbsoluteSpinner />
                ) : (
                  <Pools
                    pools={historyPools}
                    userAddress={userAddress}
                    refetchPool={refetchPool}
                    setFilters={setFilters}
                  />
                )}
              </TabPanel>
            </TabPanels>
          </Tabs>
        ) : (
          <Text textAlign={'center'}>
            {t('pools.Connect your wallet to view your pools')}
          </Text>
        )}
      </MainContainer>
    </Page>
  )
}

export default MyPools
