import { Flex, Text } from '@chakra-ui/react'
import React from 'react'

const PoolCardInfoBadge = React.forwardRef(
  ({ title, value, fontSize = '16px', ...props }, ref) => {
    return (
      <Flex
        px={'8px'}
        py={{ base: '10px', sm: '12px' }}
        color={'white'}
        borderRadius={'12px'}
        alignItems={'center'}
        justifyContent={'center'}
        textAlign={'center'}
        ref={ref}
        {...props}
      >
        <Text fontWeight={600} fontSize={fontSize}>
          {title} {value}
        </Text>
      </Flex>
    )
  },
)

export default PoolCardInfoBadge
