import { config } from '@/configs/wagmi'
import { CONTRACT_ABI_BY_VERSIONS } from '@/constants/v2/poolVersions'
import { userInfoSerializers } from '@/core/serializers/v2'
import timestampToMiliSeconds from '@/utils/timestampToMiliSeconds'
import { readContract } from '@wagmi/core'
import { format } from 'date-fns'
import { formatEther, formatUnits } from 'viem'
import { getBlock, getBlockNumber, getContractEvents } from 'viem/actions'

export const getUserInfo = async ({
  poolAddress,
  userAddress,
  version,
  yieldSource,
  token,
}) => {
  if (!userAddress) return {}

  const userId = await getUserId({
    poolAddress,
    userAddress,
    version,
    yieldSource,
    token,
  })

  if (!userId) return {}

  const [userInfo, chance, winningNumbers] = await Promise.all([
    readContract(config, {
      args: [userId],
      abi: CONTRACT_ABI_BY_VERSIONS[yieldSource][token][version],
      address: poolAddress,
      functionName: 'userInfo',
    }),

    getChance({ poolAddress, userAddress, version, yieldSource, token }),

    getUserWinningNumbers({
      poolAddress,
      userAddress,
      version,
      yieldSource,
      token,
    }),
  ])

  return {
    ...userInfoSerializers[version](userInfo),
    id: userId,
    chance,
    winningNumbers,
  }
}

export const getUserId = async ({
  poolAddress,
  userAddress,
  version,
  yieldSource,
  token,
}) => {
  try {
    const userId = await readContract(config, {
      functionName: 'userID',
      args: [userAddress],
      abi: CONTRACT_ABI_BY_VERSIONS[yieldSource][token][version],
      address: poolAddress,
      query: { enabled: !!userAddress },
    })

    return userId
  } catch (err) {
    console.log('Error in getUserId: ', err.message)
    return null
  }
}

export const getChance = async ({
  poolAddress,
  userAddress,
  version,
  yieldSource,
  token,
}) => {
  try {
    const userChance = await readContract(config, {
      functionName: 'getChance',
      args: [userAddress],
      abi: CONTRACT_ABI_BY_VERSIONS[yieldSource][token][version],
      address: poolAddress,
    })

    return userChance
  } catch (err) {
    console.error(err.message)
    return 0n
  }
}

const getUserWinningNumbers = async ({
  version,
  userAddress,
  poolAddress,
  yieldSource,
  token,
}) => {
  try {
    const winningNumbers = await readContract(config, {
      args: [userAddress],
      abi: CONTRACT_ABI_BY_VERSIONS[yieldSource][token][version],
      account: userAddress,
      address: poolAddress,
      functionName: 'check',
    })

    return winningNumbers
  } catch (err) {
    console.error(err.message)
    return []
  }
}

export const getCurrentUserID = async ({
  poolAddress,
  version,
  yieldSource,
  token,
}) => {
  try {
    const currentUserIDInPool = await readContract(config, {
      functionName: 'currentUserID',
      args: [],
      abi: CONTRACT_ABI_BY_VERSIONS[yieldSource][token][version],
      address: poolAddress,
    })

    return currentUserIDInPool
  } catch (err) {
    console.error(err.message)
    return null
  }
}

export const getAddressOfUser = async ({
  poolAddress,
  version,
  userId,
  yieldSource,
  token,
}) => {
  try {
    const userAddress = await readContract(config, {
      functionName: 'IDToUser',
      args: [userId],
      abi: CONTRACT_ABI_BY_VERSIONS[yieldSource][token][version],
      address: poolAddress,
    })

    return userAddress
  } catch (err) {
    console.error(err.message)
    return null
  }
}

export const getHistory = async (
  client,
  {
    poolAddress,
    version,
    userAddress,
    startBlock,
    endBlock,
    appVersion,
    yieldSource,
    decimals,
    token,
  },
) => {
  try {
    const numericStartBlock = Number(startBlock)
    const batchSize = 1000
    const currentEndBlock =
      endBlock === 'latest'
        ? Number(await getBlockNumber(client))
        : Number(endBlock)

    const allLogs = []
    const fetchPromises = []

    for (
      let toBlock = currentEndBlock;
      toBlock >= numericStartBlock;
      toBlock -= batchSize
    ) {
      const fromBlock = Math.max(toBlock - batchSize + 1, numericStartBlock)

      fetchPromises.push(
        fetchLogsPerBatch(client, {
          fromBlock,
          toBlock,
          poolAddress,
          userAddress,
          version,
          appVersion,
          yieldSource,
          decimals,
          token,
        }),
      )
    }

    const results = await Promise.all(fetchPromises)
    for (const result of results) {
      allLogs.push(...result)
    }

    return allLogs.sort((a, b) => Number(b.timestamp) - Number(a.timestamp))
  } catch (err) {
    console.error(err)
    return []
  }
}

const fetchLogsPerBatch = async (
  client,
  {
    version,
    poolAddress,
    userAddress,
    fromBlock,
    toBlock,
    yieldSource,
    decimals,
    token,
  },
) => {
  const rawLogs = await getContractEvents(client, {
    abi: CONTRACT_ABI_BY_VERSIONS[yieldSource][token][version],
    address: poolAddress,
    eventName: 'Stake',
    args: { user: userAddress },
    fromBlock: BigInt(fromBlock),
    toBlock: BigInt(toBlock),
  })

  return Promise.all(
    rawLogs.map(async (log) => {
      const { blockNumber, args } = log
      const {
        amount: deposit = 0n,
        totalTickets,
        userTickets,
        timestamp = 0n,
      } = args

      const chance = userTickets
        ? (formatEther(userTickets) / formatEther(totalTickets)) * 100
        : null

      let blockTimestamp = timestamp
      if (!timestamp) {
        const { timestamp: blockTime } = await getBlock(client, {
          blockNumber,
        })
        blockTimestamp = blockTime
      }

      const date = new Date(Number(timestampToMiliSeconds(blockTimestamp)))

      return {
        date: format(date, 'dd/MM/yy'),
        timestamp: blockTimestamp,
        deposit: formatUnits(deposit, decimals),
        chance,
      }
    }),
  )
}
