import * as modals from '@/components/modals'
import MODALS from '@/constants/modals'
import { useDisclosure } from '@chakra-ui/react'
import { createContext, useState } from 'react'

export const ModalsContext = createContext({
  openModal: (_modal, _props = {}) => {},
  closeModal: (_modal) => {},
})

const ModalsProvider = ({ children }) => {
  const [modal, setModal] = useState(MODALS.Confirm)
  const [modalProps, setModalProps] = useState({})
  const { isOpen: isModalOpen, onOpen, onClose } = useDisclosure()

  const updateModalProps = (props) => {
    setModalProps((prevModalProps) => ({ ...prevModalProps, ...props }))
  }

  const openModal = (modalName, props) => {
    setModal(modalName)
    setModalProps(props)
    onOpen()
  }

  const closeModal = () => {
    onClose()
    setModalProps({})
    setModal(MODALS.Confirm)
  }

  const ModalComponent = modals[modal]

  return (
    <ModalsContext.Provider value={{ openModal, closeModal, updateModalProps }}>
      <ModalComponent
        isOpen={isModalOpen}
        onClose={closeModal}
        {...modalProps}
      />
      {children}
    </ModalsContext.Provider>
  )
}

export default ModalsProvider
