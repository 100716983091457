import { Badge } from '@chakra-ui/react'

const PoolStatusBadge = ({ statusColor, statusText }) => (
  <Badge
    p={'4px 8px'}
    borderRadius={'50px'}
    colorScheme={statusColor}
    color={'white'}
    fontSize={'12px'}
    fontWeight={700}
    variant='solid'
  >
    {statusText}
  </Badge>
)

export default PoolStatusBadge
