export const MutuariAbi = [
  {
    inputs: [],
    name: 'getGeneralInfo',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
    ],
    name: 'getSupplyMarkets',
    outputs: [
      {
        components: [
          {
            internalType: 'address',
            name: 'tokenAddress',
            type: 'address',
          },
          {
            internalType: 'address',
            name: 'lendTokenAddress',
            type: 'address',
          },
          {
            internalType: 'string',
            name: 'symbol',
            type: 'string',
          },
          {
            internalType: 'uint256',
            name: 'decimals',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'totalSupplied',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'maxSupplyLimit',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'tokenRate',
            type: 'uint256',
          },
          {
            internalType: 'int256',
            name: 'apy',
            type: 'int256',
          },
          {
            internalType: 'uint256',
            name: 'ltv',
            type: 'uint256',
          },
          {
            internalType: 'bool',
            name: 'canBeCollateral',
            type: 'bool',
          },
          {
            internalType: 'uint256',
            name: 'walletBalance',
            type: 'uint256',
          },
        ],
        internalType: 'struct IMutuariGetter.SupplyMarket[]',
        name: '',
        type: 'tuple[]',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
    ],
    name: 'getBorrowMarkets',
    outputs: [
      {
        components: [
          {
            internalType: 'address',
            name: 'tokenAddress',
            type: 'address',
          },
          {
            internalType: 'address',
            name: 'debtTokenAddress',
            type: 'address',
          },
          {
            internalType: 'string',
            name: 'symbol',
            type: 'string',
          },
          {
            internalType: 'uint256',
            name: 'decimals',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'available',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'reserveBalance',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'totalBorrowed',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'maxBorrowLimit',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'tokenRate',
            type: 'uint256',
          },
          {
            internalType: 'int256',
            name: 'apy',
            type: 'int256',
          },
          {
            internalType: 'uint256',
            name: 'walletBalance',
            type: 'uint256',
          },
        ],
        internalType: 'struct IMutuariGetter.BorrowMarket[]',
        name: '',
        type: 'tuple[]',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
    ],
    name: 'getUserPortfolio',
    outputs: [
      {
        components: [
          {
            internalType: 'int256',
            name: 'netAPY',
            type: 'int256',
          },
          {
            internalType: 'int256',
            name: 'netWorth',
            type: 'int256',
          },
          {
            internalType: 'uint256',
            name: 'healthFactor',
            type: 'uint256',
          },
        ],
        internalType: 'struct IMutuariGetter.UserPortfolio',
        name: '',
        type: 'tuple',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
    ],
    name: 'getUserSupplies',
    outputs: [
      {
        components: [
          {
            internalType: 'address',
            name: 'tokenAddress',
            type: 'address',
          },
          {
            internalType: 'address',
            name: 'lendTokenAddress',
            type: 'address',
          },
          {
            internalType: 'string',
            name: 'symbol',
            type: 'string',
          },
          {
            internalType: 'uint256',
            name: 'decimals',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'available',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'reserveBalance',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'balance',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'tokenRate',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'apy',
            type: 'uint256',
          },
          {
            internalType: 'bool',
            name: 'canBeCollateral',
            type: 'bool',
          },
        ],
        internalType: 'struct IMutuariGetter.UserSupplies[]',
        name: '',
        type: 'tuple[]',
      },
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
    ],
    name: 'getUserBorrows',
    outputs: [
      {
        components: [
          {
            internalType: 'address',
            name: 'tokenAddress',
            type: 'address',
          },
          {
            internalType: 'address',
            name: 'debtTokenAddress',
            type: 'address',
          },
          {
            internalType: 'string',
            name: 'symbol',
            type: 'string',
          },
          {
            internalType: 'uint256',
            name: 'decimals',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'balance',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'tokenRate',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'apy',
            type: 'uint256',
          },
        ],
        internalType: 'struct IMutuariGetter.UserBorrows[]',
        name: '',
        type: 'tuple[]',
      },
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
]
