import { MutuariAbi } from '@/abis/v2/MutuariAbi'
import { config } from '@/configs/wagmi'
import { LOLIK_APR, PROTOCOL_FEE, apxSafeStartBlock } from '@/constants/common'
import POOL_STATUSES from '@/constants/poolStatus'
import oneDayInMilliseconds, { oneYearInMiliseconds } from '@/constants/times'
import {
  CONTRACT_ABI_BY_VERSIONS,
  YIELD_SOURCES,
} from '@/constants/v2/poolVersions'
import { poolSerializers } from '@/core/serializers/v2'
import {
  getAddressOfUser,
  getCurrentUserID,
  getUserInfo,
} from '@/core/user/v2/read'
import { Big } from '@/utils/big'
import { removeDuplicatesFromArray } from '@vaultwin/utils'
import { readContract, readContracts } from '@wagmi/core'
import { erc20Abi, formatEther, formatUnits, parseAbi, zeroAddress } from 'viem'
import { getBalanceOfPool } from '../common'

export const getAllowance = async ({ address, tokenAddress, spender }) => {
  try {
    const allowance = await readContract(config, {
      abi: erc20Abi,
      address: tokenAddress,
      functionName: 'allowance',
      args: [address, spender],
    })

    return allowance
  } catch (err) {
    console.error('Error getting allowance: ', err)
    return 0
  }
}

export const getPool = async ({ poolAddress, userAddress, metadata }) => {
  try {
    const { version, yieldSource, token, lendToken } = metadata

    const [userInfo, poolInfo, apr, { startBlock, endBlock }] =
      await Promise.all([
        getUserInfo({
          poolAddress,
          userAddress,
          version,
          yieldSource,
          token,
        }),
        getPoolInfo({
          poolAddress,
          version,
          yieldSource,
          lendToken,
          token,
        }),
        getAPR({ yieldSource, lendToken }),
        getBlockNumbers({ poolAddress }),
      ])

    return {
      poolInfo,
      address: poolAddress,
      userInfo,
      startBlock: startBlock || apxSafeStartBlock,
      endBlock: endBlock || 'latest',
      apr,
      id: '0',
      ...metadata,
    }
  } catch (err) {
    console.error('Error getting pool: ', err.message)

    return null
  }
}

export const getBlockNumbers = async ({ poolAddress }) => {
  try {
    const contract = {
      abi: parseAbi([
        'function i_block() view returns (uint256)',
        'function end_block() view returns (uint256)',
      ]),
      address: poolAddress,
    }

    const [startBlock, endBlock] = await readContracts(config, {
      contracts: [
        {
          ...contract,
          functionName: 'i_block',
        },
        {
          ...contract,
          functionName: 'end_block',
        },
      ],
    })

    return {
      startBlock: startBlock.result,
      endBlock: endBlock.result,
    }
  } catch (e) {
    console.error('Error getting block numbers: ', e)
    return {}
  }
}

export const getPoolInfo = async ({
  poolAddress,
  version,
  yieldSource,
  lendToken,
  token,
}) => {
  try {
    const rawPoolInfo = await readContract(config, {
      abi: CONTRACT_ABI_BY_VERSIONS[yieldSource][token][version],
      address: poolAddress,
      functionName: 'poolInfo',
    })

    const poolInfo = poolSerializers[version](rawPoolInfo)

    const [players, { accumulatedReward, finalReward }] = await Promise.all([
      getTotalActivePlayers({ poolAddress, version, yieldSource, token }),
      getPoolPotentialRewards({
        poolAddress,
        totalDeposit: poolInfo.totalDeposit,
        rewardForRound: poolInfo.rewardForRound,
        start: poolInfo.start,
        duration: poolInfo.duration,
        isFinished: poolInfo.status === POOL_STATUSES.finished,
        version,
        yieldSource,
        lendToken,
        token,
      }),
    ])

    return {
      ...poolInfo,
      players: Number(players),
      accumulatedReward,
      finalReward,
    }
  } catch (err) {
    console.error('Error getting pool info: ', err.message)
    return {}
  }
}

export const getMutuariAddress = async ({
  poolAddress,
  version,
  yieldSource,
  token,
}) => {
  const mutuariAddress = await readContract(config, {
    functionName: 'mutuari',
    abi: CONTRACT_ABI_BY_VERSIONS[yieldSource][token][version],
    address: poolAddress,
  })

  return mutuariAddress
}

export const getWinners = async ({
  poolAddress,
  version,
  yieldSource,
  token,
}) => {
  const winners = await readContract(config, {
    functionName: 'getWinners',
    args: [],
    abi: CONTRACT_ABI_BY_VERSIONS[yieldSource][token][version],
    address: poolAddress,
  })

  const userChancePromises = removeDuplicatesFromArray(winners).map(
    async (address) => {
      try {
        const { chance, depositInPool, finalDeposit } = await getUserInfo({
          poolAddress,
          userAddress: address,
          version,
          yieldSource,
          token,
        })

        const result = {
          address,
          chance: chance ? formatEther(chance) : 0,
          deposit: depositInPool || finalDeposit,
        }

        if (!result.chance) {
          // || !result.deposit TODO: maybe revert when deposit is not 0 after claim
          throw new Error('Failed to get chance and deposit for user')
        }

        return result
      } catch (error) {
        console.error(
          `Failed to get chance for user at address ${address}:`,
          error.message,
        )
        return { address, chance: 0, deposit: 0n }
      }
    },
  )

  const usersWithChance = await Promise.all(userChancePromises)

  return usersWithChance.sort((a, b) => Number(b.chance) - Number(a.chance))
}

export const getTopPlayers = async ({
  poolAddress,
  version,
  yieldSource,
  token,
}) => {
  try {
    const lastUserIdInPool = await getCurrentUserID({
      poolAddress,
      version,
      yieldSource,
      token,
    })

    const userIdsInPool = Array.from(
      { length: Number(lastUserIdInPool) },
      (_, i) => i + 1,
    )

    const userAddressPromises = userIdsInPool.map(async (userId) => {
      try {
        const address = await getAddressOfUser({
          poolAddress,
          userId,
          version,
          yieldSource,
          token,
        })

        return { id: userId, address }
      } catch (error) {
        console.error(
          `Failed to get address for user ${userId}:`,
          error.message,
        )
        return { id: userId, address: null }
      }
    })

    const usersWithAddress = await Promise.all(userAddressPromises)
    const userChancePromises = usersWithAddress.map(async ({ address, id }) => {
      if (!address) {
        throw new Error(`No address for user ${id}`)
      }

      try {
        const { chance, depositInPool, finalDeposit } = await getUserInfo({
          poolAddress,
          userAddress: address,
          version,
          yieldSource,
          token,
        })

        return {
          id,
          address,
          chance: chance ? formatEther(chance) : 0,
          deposit: depositInPool || finalDeposit,
        }
      } catch (error) {
        console.error(error.message)
        return null
      }
    })
    const usersWithChance = await Promise.all(userChancePromises)

    return usersWithChance
      .filter((user) => user && user.deposit > 0n)
      .sort((a, b) => Number(b.chance) - Number(a.chance))
  } catch (error) {
    console.error('Failed to get leaderboard:', error.message)
    return []
  }
}

export const getTotalActivePlayers = async ({
  poolAddress,
  version,
  yieldSource,
  token,
}) => {
  const allPlayers = await getTopPlayers({
    poolAddress,
    version,
    yieldSource,
    token,
  })

  return allPlayers.filter((player) => player.chance && player.deposit > 0n)
    .length
}

export const getPoolPotentialRewards = async ({
  poolAddress,
  totalDeposit = 0n,
  rewardForRound = 0n,
  currentTimestamp = BigInt(Date.now()),
  start = 0n,
  duration = 0n,
  version,
  isFinished = false,
  lendToken,
  yieldSource,
  token,
}) => {
  try {
    if (isFinished) {
      return { accumulatedReward: rewardForRound, finalReward: rewardForRound }
    }

    const isLolik = yieldSource === YIELD_SOURCES.LOLIK

    const [fee, balance, apr] = await Promise.all([
      getPoolFee({ poolAddress, version, yieldSource, token }),
      getBalanceOfPool({ poolAddress, tokenAddress: lendToken }),
      getAPR({ yieldSource, lendToken }),
    ])

    const safeRewardForRound = ((balance - totalDeposit) * (100n - fee)) / 100n

    // TODO: move to helper or utils the same for V1
    if (isLolik) {
      if (start + duration > currentTimestamp) {
        const daysInYear = 365n
        const lidoRebaseTimeOfDay = 72600000n

        // Calculate the number of days passed in pool time
        const todaysLolikRebase =
          (currentTimestamp / oneDayInMilliseconds) * oneDayInMilliseconds +
          lidoRebaseTimeOfDay

        // Check if the last rebase event has already happened
        let nextRebase
        if (currentTimestamp < todaysLolikRebase) {
          nextRebase = todaysLolikRebase
        } else {
          nextRebase = todaysLolikRebase + oneDayInMilliseconds
        }

        const poolEnd = start + duration
        let remainingRebases = 0n

        while (nextRebase <= poolEnd) {
          remainingRebases++
          nextRebase += oneDayInMilliseconds
        }

        console.log('todaysLolikRebase: ', todaysLolikRebase)
        console.log('remainingRebases: ', remainingRebases)

        return {
          accumulatedReward: safeRewardForRound,
          finalReward:
            safeRewardForRound +
            (((totalDeposit * remainingRebases * LOLIK_APR) /
              (daysInYear * 100n)) *
              (100n - fee)) /
              100n,
        }
      }
    }

    const sessionEnd = start + duration
    const timeLeftInMiliseconds = Big(sessionEnd).minus(+new Date()).toString()
    const years = Number(timeLeftInMiliseconds) / Number(oneYearInMiliseconds)

    const reward = Number(totalDeposit) * (Number(apr) / 100) * years
    const rewardWithoutFee = reward - reward * (Number(fee) / 100)

    const finalReward = Big(safeRewardForRound)
      .plus(rewardWithoutFee)
      .round()
      .toString()

    return {
      accumulatedReward: safeRewardForRound,
      finalReward,
    }
  } catch (err) {
    console.error(err.message)

    return {
      accumulatedReward: 0n,
      finalReward: 0n,
    }
  }
}

export const getPoolFee = async ({
  poolAddress,
  yieldSource,
  version,
  token,
}) => {
  try {
    const fee = await readContract(config, {
      functionName: 'fee',
      abi: CONTRACT_ABI_BY_VERSIONS[yieldSource][token][version],
      address: poolAddress,
    })

    return fee
  } catch (err) {
    console.error(err.message)

    return PROTOCOL_FEE
  }
}

export const getAPR = async ({ yieldSource, lendToken }) => {
  try {
    const isLOLIK = yieldSource === YIELD_SOURCES.LOLIK

    if (isLOLIK) {
      return LOLIK_APR ? LOLIK_APR.toString() : 6
    }

    const mutuariImplementation = '0xed622d2d20e326a8590e6df004794cec102f5600'
    const markets = await readContract(config, {
      abi: MutuariAbi,
      functionName: 'getSupplyMarkets',
      args: [zeroAddress],
      address: mutuariImplementation,
    })

    const market = markets?.find(
      (market) => market.lendTokenAddress === lendToken,
    )
    const apr = market ? market.apy : 0n
    const formattedAPR = apr ? Number(formatUnits(apr, 25)).toFixed(1) : 0

    return formattedAPR || 0
  } catch (err) {
    console.error(err)

    return 0
  }
}
