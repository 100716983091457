import { http, createConfig } from 'wagmi'
import { injected, walletConnect } from 'wagmi/connectors'
import { bahamut, hardhat } from './chains'

export const config = createConfig({
  chains: [bahamut, hardhat],
  multiInjectedProviderDiscovery: false,
  connectors: [
    injected({ target: 'metaMask' }),
    walletConnect({
      projectId: import.meta.env.VITE_WC_PROJECT_ID,
      qrModalOptions: {
        themeVariables: {
          '--wcm-z-index': '1401',
        },
      },
    }),
  ],
  transports: {
    [bahamut.id]: http(),
    [hardhat.id]: http(),
  },
})
