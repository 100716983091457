import { LotteryAbi } from '@/abis/v2/LotteryAbi'
import { LotteryMutuariAbi } from '@/abis/v2/LotteryMutuariAbi'
import { LotteryStFtnAbi } from '@/abis/v2/LotteryStFtnAbi'
import { COINS_ADDRESSES } from '../coins'

export const YIELD_SOURCES = {
  LOLIK: '0x780Fb5AcA83F2e3F57EE18cc3094988Ef49D8c3d',
  MUTUARI: '0x9322f26CEE8d536c4BdEA5C183395aDC30e41e4B',
}

const CONTRACT_VERSIONS = {
  V1: 1, // default
}

export const CONTRACT_ABI_BY_VERSIONS = {
  [YIELD_SOURCES.LOLIK]: {
    [COINS_ADDRESSES.FTN]: {
      [CONTRACT_VERSIONS.V1]: LotteryAbi,
    },
    [COINS_ADDRESSES.STFTN]: {
      [CONTRACT_VERSIONS.V1]: LotteryStFtnAbi,
    },
  },
  [YIELD_SOURCES.MUTUARI]: {
    [COINS_ADDRESSES.USDC]: {
      [CONTRACT_VERSIONS.V1]: LotteryMutuariAbi,
    },
    [COINS_ADDRESSES.USDT]: {
      [CONTRACT_VERSIONS.V1]: LotteryMutuariAbi,
    },
  },
}

export default CONTRACT_VERSIONS
